
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between"
      >
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 200px"
            :items="classes"
            item-text="full_name"
            item-value="IDFaecher"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="mr-2"
            no-data-text="Loading..."
            clearable
        />
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 200px"
            :items="subjects"
            :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
            item-value="IDFaecher"
            label="Fächer"
            :return-object="true"
            v-model="selectedSubject"
            class="mr-2"
            no-data-text="Loading..."
            :disabled="!subjects.length"
            clearable
        />
        <v-btn-toggle
            borderless
            color="red"
            class="ml-6"
            v-model="selectedType"
        >
          <v-btn value="s">Stunden</v-btn>
          <v-btn value="b">Blöcke</v-btn>
        </v-btn-toggle>
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 200px"
            :items="filtertBlocks"
            item-text="text"
            item-value="value"
            :label="dropLable"
            :return-object="true"
            v-model="selectedBlock"
            class="ml-6 mr-2"
            no-data-text="Loading..."
            :disabled="!blocks.length"
            clearable
        />
        <v-btn
            x-large
            @click="openAbsentClass"
            class="ml-6"
        >Klassenbuch öffnen
        </v-btn>
      </v-item-group>
    </v-card-text>
  </v-card>
</v-container>
