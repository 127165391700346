<template>
  <v-container>
    <h1 class="text-center">404</h1>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>
