import {NavigationGuardNext, Route} from 'vue-router/types/router'
import {useAuth} from '@/plugins/auth'

const authSignedGuard = (to: Route, from: Route, next: NavigationGuardNext): any => {
    const auth = useAuth()

    if (['login'].includes(<string>to.name) && auth.signed()) {
        next({name: 'dashboard'})
    } else {
        next()
    }
}

export default authSignedGuard
