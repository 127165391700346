
<v-container class="py-8 px-6" fluid>
  <v-row>
    <v-col cols="6">
      <v-card elevation-4>
        <v-card-title>Stundenplan Heute</v-card-title>
        <v-card-text>
          <timetable type="day" dashboard="true"/>
        </v-card-text>
        <v-card-text>
          <v-btn @click="$router.push('/absent/change')">Abweichender Stundenplan oder Vertretung</v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" class="pa-0">
      <v-col cols="12">
        <v-card elevation-4>
          <v-img
              class="white--text align-end"
              height="200px"
              :src="linkCityPic"
              alt="weather image"
          >
            <v-card-title>Wetter</v-card-title>
          </v-img>
          <v-card-text v-if="Object.entries(weather).length > 0"
          >
            <img :src="weatherIcon" style="width: 50px; height: 50px; margin-right: 10px; margin-bottom: -10px;">
            <span>{{ cityName }}, {{ weather.temp }} Grad</span>
          </v-card-text
          >
          <v-card-text v-else>Lade...</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation-4>
          <v-card-title>Stundeninhalte</v-card-title>
          <v-card-text>
            <v-btn @click="$router.push('/contents')">Inhalte ansehen</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation-4>
          <v-card-title>Fehlzeiten</v-card-title>
          <v-card-text>
            <v-btn @click="$router.push('/absents')">Inhalte ansehen</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card elevation-4>
          <v-card-title>Hausaufgabenbetreuung</v-card-title>
          <v-card-text>
            <v-btn class="mr-5" @click="$router.push('/homework')">Anwesenheit bearbeiten</v-btn>
            <v-btn @click="$router.push('/homework/create')">Schüler verpflichten</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</v-container>
