
<v-container fluid>
  <v-card style="width: 100%" v-if="!dashboard">
    <v-card-text>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
              :loading="loading"
          >
            <v-icon small> mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
              :loading="loading"
          >
            <v-icon small> mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined :loading="exportLoading" @click="exportIcal">
            Export iCal
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-item-group
          flex
          class="flex row ml-6 pt-2 pb-2"
          style="align-content: space-between"
      >
        <!-- Stundenplan für &nbsp;
        <strong>KW {{ new Date() | moment("WW") }}</strong>-->
      </v-item-group>
    </v-card-text>
  </v-card>
  <v-card>
    <v-sheet class="mt-2" height="100%">
      <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          :first-interval="7"
          :interval-count="12"
          :min-weeks="1"
          locale="DE"
          @click:event="showEvent"
          @change="shouldGetEvents"
      >
        <template v-slot:day-body="{ date, week }">
          <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-card>
</v-container>
