<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>Statistik</v-card-title>
      <v-card-text class="flex row" style="width: 80%; margin-left: 1px">
        <ClassStudentSubjectSelect
            :class-data="classes"
            :show-student-select="false"
            :only-assessable-subjects="false"
            @updateClass="this.updateClass"
            @updateSubject="this.updateSubject"
            @updateStudents="this.updateStudents"
            @toggleLoading="this.toggleLoading"/>
        <v-item-group class="flex row ml-6 mt-1">
          <v-text-field
              label="Von"
              class="input-date"
              type="date"
              v-model="startDate"/>
          <v-text-field
              label="Bis"
              class="input-date"
              type="date"
              v-model="endDate"/>
          <v-btn
              class="d-flex align-self-center ml-5 accent"
              :disabled="!isValid || loading"
              @click="load()">
            Laden
          </v-btn>
        </v-item-group>
      </v-card-text>
    </v-card>
    <br>
    <v-card v-if="showTable">
      <v-card-text>
        <v-simple-table fixed-header height="70vh">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left t-header">Schülername</th>
              <th class="text-right t-header">Gesamtstunden</th>
              <th class="text-right t-header">Anwesend</th>
              <th class="text-right t-header">Entschuldigt</th>
              <th class="text-right t-header">Unentschuldigt</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="student in students" :key="student.IDSchueler">
              <td>{{ student.Nachname }}, {{ student.Vorname }}</td>
              <td class="text-right">{{ absentData[student.IDSchueler].ges }}</td>
              <td class="text-right">
                {{ absentData[student.IDSchueler].Anwesend }}
                <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentA }} %)</span>
              </td>
              <td class="text-right">
                {{ absentData[student.IDSchueler].FsE }}
                <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentE }} %)</span>
              </td>
              <td class="text-right">
                {{ absentData[student.IDSchueler].FsUE }}
                <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentU }} %)</span>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import ClassStudentSubjectSelect from "@/components/ClassStudentSubjectSelect.vue";
import {useHttp} from "@/core/http";

type Student = {
  IDSchueler: number,
  Vorname: string,
  Nachname: string,
}

type AbsentData = {
  ges: number,
  Anwesend: number,
  prozentA: number,
  FsE: number,
  prozentE: number,
  FsUE: number,
  prozentU: number
}

@Component({
  components: {ClassStudentSubjectSelect}
})
export default class AbsentsStatistic extends Vue {
  @Prop({required: true, default: []}) classes!: Array<any>;

  private loading: boolean = false
  private isValid: boolean = false
  private showTable: boolean = false

  private selectedClass: Record<string, any> = {}
  private subject: Record<string, any> = {}
  private students: Array<Student> = []
  private absentData: Record<number, AbsentData> = {}
  private startDate: string = "no date"
  private endDate: string = this.$moment().format("YYYY-MM-DD")

  private http = useHttp()

  private async mounted() {
    const date: string = await this.http.get('absent/startdate');
    this.startDate = this.$moment(date, "DD-MM-YYYY").format('YYYY-MM-DD');
  }

  private reset() {
    this.showTable = false
    this.absentData = {}
  }

  private toggleLoading(state: boolean) {
    this.loading = state
  }

  private updateClass(value: Record<string, any>) {
    this.selectedClass = value
    this.validateInputs()
    this.reset()
  }

  private updateSubject(value: Record<string, any>) {
    this.subject = value;
    this.validateInputs()
    this.reset()
  }

  private updateStudents(value: Array<any>) {
    this.students = value;
  }

  private validateInputs() {
    if (this.selectedClass?.IDArtSchule && this.subject?.IDFaecher && this.students.length > 0) {
      this.isValid = true
      return
    }

    this.isValid = false
  }

  private async load() {
    this.loading = true
    this.reset()

    const start = this.$moment(this.startDate)
    const end = this.$moment(this.endDate)
    if (!start.isValid() && !end.isValid()) {
      this.$swal('Fehler', 'Bitte Zeitraum festlegen.', 'warning');
      return
    }

    if (end < start) {
      this.$swal('Fehler', 'Das Enddatum kann nicht vor dem Startdatum liegen.', 'warning');
      return
    }

    const promises: any[] = []
    this.students.forEach(student => {
      promises.push(this.loadAbsents(student))
    })

    await Promise.all(promises)
    this.loading = false
    this.showTable = true
  }

  private loadAbsents(student: Record<string, any>) {
    return new Promise(resolve => {
      this.http.get(`/absent/student/${student.IDSchueler}/${this.startDate}/${this.endDate}?consider_all_subjects=1`).then((data: any) => {
        if (data.subjects && data.subjects[this.subject.IDFaecher]) {
          this.absentData[student.IDSchueler] = data.subjects[this.subject.IDFaecher]
          resolve(true)
          return
        } else {
          this.absentData[student.IDSchueler] = {
            ges: 0,
            Anwesend: 0,
            prozentA: 0,
            FsE: 0,
            prozentE: 0,
            FsUE: 0,
            prozentU: 0
          }
          resolve(true)
          return
        }
      })
    })
  }
}
</script>

<style scoped>
.input-date {
  margin-right: 0.5rem;
}

.input-date {
  width: 12rem;
}

tbody tr td {
  height: 50px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(65, 65, 65, 1);
}

thead tr, tbody tr:nth-of-type(even) {
  background-color: rgba(30, 30, 30, 1);
}

.t-header {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
</style>
