<template>
  <div>
    <v-card :loading="loading">
      <v-card-title>Anwesenheit</v-card-title>
      <v-card-text>
        <v-item-group
            flex
            class="flex row ml-6 mt-1"
            style="align-content: space-between">
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 160px"
              :items="classes"
              item-text="full_name"
              :return-object="true"
              label="Klasse"
              v-model="selectedClass"
              class="mr-2"
              no-data-text="Laden..."
              clearable/>
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 320px"
              :items="weeks"
              item-text="text"
              item-value="kw"
              label="Kalenderwoche"
              :return-object="true"
              v-model="selectedWeek"
              class="mr-2"
              no-data-text="Laden..."
              :disabled="!(selectedClass && selectedClass.IDArtSchule)"/>
        </v-item-group>
      </v-card-text>
    </v-card>
    <br/>
    <v-card>
      <v-card-text v-show="selectedClass && selectedClass.IDArtSchule && selectedWeek.start">
        <div style="overflow: scroll; color: #fff">
          <div style="width: 2200px; height: 70vh">
            <table style="width: 100%">
              <thead style="font-weight: bold">
              <tr>
                <th class="sticky-both">
                  <div class="table-header">Schülername</div>
                </th>
                <th class="sticky-row"
                    v-for="weekday in weekdays"
                    v-bind:key="weekday">
                  <div class="table-header">{{ weekday }}</div>
                  <div class="item"
                       v-for="colNumber in lessons"
                       v-bind:key="colNumber">
                    {{ colNumber }}
                  </div>
                </th>
              </tr>
              </thead>
              <tbody style="border: 1px solid">
              <tr v-for="item in orderedTimes()" v-bind:key="item.id+'-row'">
                <td class="sticky-col" style="padding-left: 0.5rem">
                  {{ item.nachname }}, {{ item.vorname }}
                </td>
                <td v-for="(times, idx) in item.times" v-bind:key="item.id+'weekday-'+idx">
                  <div
                      class="item"
                      v-for="(day, idx) in times"
                      style="font-weight: bold;"
                      v-bind:key="item.id+'-lesson-'+idx"
                      v-bind:style="{ backgroundColor: colors[day] }">
                    <span v-if="day === 'false'">*</span>
                    <span v-else>{{ day }}</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {useHttp} from "@/core/http";
import _ from 'lodash';
import {Moment} from "moment/moment";

type CalendarWeek = {
  start: string,
  end: string,
  text: string,
  week: string
}

@Component({})
export default class AbsentsTable extends Vue {
  @Prop({required: true, default: []}) classes!: Array<any>;

  private loading = false;
  private selectedWeek: CalendarWeek = {start: "", end: "", text: "", week: ""};
  private selectedClass: Record<string, any> = {};

  private weeks: Array<any> = [];
  private lessons: Array<number> = [];
  private maxLessons = 0;
  private weekdays: Array<string> = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"]
  private colors: Record<string, string> = {};

  private absentData: any = null;
  private schedule: any = null;

  private http = useHttp()

  private async mounted() {
    await this.getCalendarWeeks()
    await this.prepareColumns()
  }

  @Watch('selectedWeek')
  @Watch('selectedClass')
  private async update() {
    if (this.selectedClass?.IDArtSchule && this.selectedWeek.start !== "" && this.selectedWeek.end !== "") {
      this.loading = true
      this.http.get(`absent/${this.selectedClass.IDArtSchule}/${this.selectedWeek.start}/${this.selectedWeek.end}`)
          .then((data: any) => {
            const {absentTimes, maxCourses, schedule, colors} = data
            this.absentData = absentTimes
            this.maxLessons = maxCourses
            this.schedule = schedule
            this.colors = colors
            this.loading = false
          })
    }
  }

  private async getCalendarWeeks() {
    const startDate: string = await this.http.get('schedule/start-school-year');

    const startWeek = this.$moment(startDate, 'YYYY-MM-DD');
    const today = this.$moment();
    const lastWeekNumber: number = today.diff(startWeek, 'week')

    this.weeks.push(this.newCalendarWeekFrom(today))
    for (let i = 1; i <= lastWeekNumber; i++) {
      let date = this.$moment().subtract(i, 'weeks')
      this.weeks.push(this.newCalendarWeekFrom(date))
    }
  }

  private async prepareColumns() {
    const result = await this.http.get('params/?param=numberOfCourses') as Record<string, number>;
    this.maxLessons = result.data

    this.lessons = [];
    for (let i = 1; i <= this.maxLessons; i++) {
      this.lessons.push(i);
    }
  }

  private orderedTimes() {
    return _.orderBy(this.absentData, 'nachname')
  }

  private newCalendarWeekFrom(date: Moment): CalendarWeek {
    const start = date.clone().startOf('isoWeek')
    const end = date.clone().endOf('isoWeek')
    const calendarWeek = date.isoWeek().toString()

    return {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
      text: `KW ${calendarWeek} (${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')})`,
      week: calendarWeek
    }
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(65, 65, 65, 1);
}

tbody tr td {
  height: 50px;
}

thead tr, tbody tr:nth-of-type(even) {
  background-color: rgba(30, 30, 30, 1);
}

.sticky-col {
  position: sticky;
  background-color: inherit;
  left: 0;
  z-index: 1;
}

.sticky-row {
  position: sticky;
  background-color: inherit;
  top: 0;
  z-index: 2;
}

.sticky-both {
  position: sticky;
  background-color: inherit;
  left: 0;
  top: 0;
  z-index: 3;
}

.table-header {
  padding-left: 0.5rem;
  text-align: left
}

.item {
  width: 30px;
  padding: 5px;
  float: left;
  text-align: center;
  border-right: 1px solid #3c3c3c;
}
</style>
