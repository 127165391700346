
<v-container fluid>
    <v-card style="width: 100%" :loading="loading">
        <v-card-text>
            <ClassStudentSubjectSelect
                @updateClass="this.updateClass"
                @updateStudent="this.updateStudent"
                @updateSubject="this.updateSubject"
                @toggleLoading="this.toggleLoading"/>
        </v-card-text>
    </v-card>

    <SubGradeGrid :key="forceRerender"
                  :gradesOpen="gradesOpen"
                  :selectedClass="studentClass"
                  :selectedStudent="student"
                  :selectedSubject="studentSubject"
                  :avatarURL="avatar"
                  :posGrades="posGrades"
                  :posExamGrades="posExamGrades"
                  :posLBGrades="posLBGrades"
                  :absentStartDate="absentStartDate"
                  v-if="dataIsComplete"/>
</v-container>
