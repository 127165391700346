
<v-card style="width: 100%; margin-top:10px;">
    <v-card-text>
        <v-row no-gutters>
            <v-col md="3">
                <v-avatar color="primary" size="180">
                    <v-img :src="this.avatarURL"></v-img>
                </v-avatar>
                <v-list-item color="rgba(0, 0, 0, .4)" dark>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{ this.selectedStudent.Nachname }}, {{ this.selectedStudent.Vorname }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item>
                <v-list-item v-if="isEmpty(absents)">
                    <v-progress-circular indeterminate color="primary"/>
                </v-list-item>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">Fehlstunden {{
                                this.selectedSubject.FachName
                            }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: .75rem; margin-bottom: 12px;">
                            ({{ this.$moment(this.absentStartDate, "DD-MM-YYYY").format("DD.MM.YYYY") }} -
                            {{ this.$moment(this.absentEndDate, "YYYY-MM-DD").format("DD.MM.YYYY") }})
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>Gesamt: {{ this.absents.ges }}</v-list-item-subtitle>
                        <v-list-item-subtitle>Anwesend: {{ this.absents.Anwesend }} ({{
                                this.absents.prozentA
                            }}%)
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>Entschuldigt: {{ this.absents.FsE }} ({{
                                this.absents.prozentE
                            }}%)
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>Unentschuldigt: {{ this.absents.FsUE }} ({{
                                this.absents.prozentU
                            }}%)
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <p class="primary--text m-3" v-if="!gradesOpen">
                    Die Notenabgabe für die Leistungsbeurteilungen und Halbjahre ist gesperrt, solange die
                    Verwaltung dies
                    nicht explizit freigibt.<br>
                    Die Abgabe der Quartalsnoten für Klausuren, mündliche und sonstige Leistungen ist jederzeit
                    möglich.
                </p>
            </v-col>
            <v-col md="9">
                <v-container v-if="!showGradeCard">
                    <v-progress-circular indeterminate color="primary"/>
                </v-container>
                <v-container v-else>
                    <v-simple-table>
                        <template>
                            <thead>
                            <tr class="gradeHeaders">
                                <th class="text-left" colspan="6">
                                    1. Leistungsbeurteilung:
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="gradeHeadersSmall">
                                <td class="text-left" colspan="4">
                                </td>
                                <td>
                                    1. Note
                                </td>
                                <td>
                                    Ø
                                </td>
                            </tr>
                            <tr>
                                <td>{{ examTxt }}:</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-select
                                        :items="posExamGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference]['exam']"
                                        :return-object="true"
                                        :readonly="blockMinorFirstExam"
                                        @change="updateGrade(subGrades[startConference]['exam'], 'exam', startConference)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference]["exam"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference]['exam']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Mündliche/sonstige Leistungen:</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference]['verbal']"
                                        :return-object="true"
                                        @change="updateGrade(subGrades[startConference]['verbal'], 'verbal', startConference)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference]["verbal"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference]['verbal']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Berechnete Note:</td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference]['main']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Erteilte Note:</td>
                                <td>
                                    <v-select
                                        :items="posLBGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        :return-object="true"
                                        filled
                                        dense
                                        style="margin-top:12px;"
                                        v-model="grades[startConference]['final']"
                                        :readonly="disableMainGrade(startConference)"
                                        @change="updateFinalGrade(grades[startConference]['final'])"
                                    ></v-select>
                                    <p class="metaLine">{{ grades[startConference]["final"]["meta"] }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table class="mt-5">
                        <template>
                            <thead>
                            <tr class="gradeHeaders">
                                <th class="text-left" colspan="6">
                                    1. Halbjahr:
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="gradeHeadersSmall">
                                <td class="text-left" colspan="3"></td>
                                <td>1. Note</td>
                                <td>2. Note</td>
                                <td>
                                    Ø
                                </td>
                            </tr>
                            <tr>
                                <td>{{ examTxt }}:</td>
                                <td></td>
                                <td></td>
                                <td>{{ subGrades[startConference]["exam"][selectedGradeType] }}</td>
                                <td>
                                    <v-select
                                        :items="posExamGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 1]['exam']"
                                        :return-object="true"
                                        :readonly="blockMinorSecondExam"
                                        @change="updateGrade(subGrades[startConference + 1]['exam'], 'exam', startConference + 1)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 1]["exam"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 1]['exam']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Mündliche/sonstige Leistungen:</td>
                                <td></td>
                                <td></td>
                                <td>{{ subGrades[startConference]["verbal"][selectedGradeType] }}</td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 1]['verbal']"
                                        :return-object="true"
                                        @change="updateGrade(subGrades[startConference + 1]['verbal'], 'verbal', startConference + 1)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 1]["verbal"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 1]['verbal']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Berechnete Note:</td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 1]['main']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Erteilte Note:</td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        :return-object="true"
                                        filled
                                        dense
                                        style="margin-top:12px;"
                                        v-model="grades[startConference + 1]['final']"
                                        :readonly="disableMainGrade(startConference + 1)"
                                        @change="updateFinalGrade(grades[startConference + 1]['final'])"
                                    ></v-select>
                                    <p class="metaLine">{{ grades[startConference + 1]["final"]["meta"] }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table class="mt-5" v-if="showSecondLB">
                        <template>
                            <thead>
                            <tr class="gradeHeaders">
                                <th class="text-left" colspan="6">
                                    2. Leistungsbeurteilung:
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="gradeHeadersSmall">
                                <td class="text-left" colspan="2"></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">1. Note</td>
                                <td v-else></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">2. Note</td>
                                <td v-else></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">3. Note</td>
                                <td v-else>1. Note</td>
                                <td>
                                    Ø
                                </td>
                            </tr>
                            <tr>
                                <td>{{ examTxt }}:</td>
                                <td></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference]["exam"][selectedGradeType] }}
                                </td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference + 1]["exam"][selectedGradeType] }}
                                </td>
                                <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                <td>
                                    <v-select
                                        :items="posExamGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 2]['exam']"
                                        :return-object="true"
                                        :readonly="blockMinorThirdExam"
                                        @change="updateGrade(subGrades[startConference + 2]['exam'], 'exam', startConference + 2)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 2]["exam"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 2]['exam']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Mündliche/sonstige Leistungen:</td>
                                <td></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference]["verbal"][selectedGradeType] }}
                                </td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference + 1]["verbal"][selectedGradeType] }}
                                </td>
                                <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 2]['verbal']"
                                        :return-object="true"
                                        @change="updateGrade(subGrades[startConference + 2]['verbal'], 'verbal', startConference + 2)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 2]["verbal"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 2]['verbal']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Berechnete Note:</td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 2]['main']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Erteilte Note:</td>
                                <td>
                                    <v-select
                                        :items="posLBGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        :return-object="true"
                                        filled
                                        dense
                                        style="margin-top:12px;"
                                        v-model="grades[startConference + 2]['final']"
                                        :readonly="disableMainGrade(startConference + 2)"
                                        @change="updateFinalGrade(grades[startConference + 2]['final'])"
                                    ></v-select>
                                    <p class="metaLine">{{ grades[startConference + 2]["final"]["meta"] }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table class="mt-5">
                        <template>
                            <thead>
                            <tr class="gradeHeaders">
                                <th class="text-left" colspan="6">
                                    {{ secondHalfYearTxt }}:
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="gradeHeadersSmall">
                                <td class="text-left"></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">1. Note</td>
                                <td v-else></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">2. Note</td>
                                <td v-else></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">3. Note</td>
                                <td v-else-if="selectedClass.full_name.includes('BG') && showSecondLB">1. Note</td>
                                <td v-else></td>
                                <td v-if="!selectedClass.full_name.includes('BG')">4. Note</td>
                                <td v-else-if="selectedClass.full_name.includes('BG') && showSecondLB">2. Note</td>
                                <td v-else>1. Note</td>
                                <td>
                                    Ø
                                </td>
                            </tr>
                            <tr>
                                <td>{{ examTxt }}:</td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference]["exam"][selectedGradeType] }}
                                </td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference + 1]["exam"][selectedGradeType] }}
                                </td>
                                <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                <td v-if="showSecondLB">{{
                                        subGrades[startConference + 2]["exam"][selectedGradeType]
                                    }}
                                </td>
                                <td v-if="!showSecondLB"></td>
                                <td>
                                    <v-select
                                        :items="posExamGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 3]['exam']"
                                        :return-object="true"
                                        :readonly="blockMinorFourthExam"
                                        @change="updateGrade(subGrades[startConference + 3]['exam'], 'exam', startConference + 3)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 3]["exam"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 3]['exam']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Mündliche/sonstige Leistungen:</td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference]["verbal"][selectedGradeType] }}
                                </td>
                                <td v-if="!selectedClass.full_name.includes('BG')">
                                    {{ subGrades[startConference + 1]["verbal"][selectedGradeType] }}
                                </td>
                                <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                <td v-if="showSecondLB">
                                    {{ subGrades[startConference + 2]["verbal"][selectedGradeType] }}
                                </td>
                                <td v-if="!showSecondLB"></td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        v-model="subGrades[startConference + 3]['verbal']"
                                        :return-object="true"
                                        @change="updateGrade(subGrades[startConference + 3]['verbal'], 'verbal', startConference + 3)"
                                    ></v-select>
                                    <p class="metaLine">{{ subGrades[startConference + 3]["verbal"]["meta"] }}</p>
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 3]['verbal']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Berechnete Note:</td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="averages[startConference + 3]['main']"
                                    ></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-left" colspan="5">Erteilte Note:</td>
                                <td>
                                    <v-select
                                        :items="posGrades"
                                        label="Note auswählen..."
                                        :item-text="selectedGradeType"
                                        item-value="IDNotenZensur"
                                        single-line
                                        :return-object="true"
                                        filled
                                        dense
                                        style="margin-top:12px;"
                                        v-model="grades[startConference + 3]['final']"
                                        :readonly="disableMainGrade(startConference + 3)"
                                        @change="updateFinalGrade(grades[startConference + 3]['final'])"
                                    ></v-select>
                                    <p class="metaLine">{{ grades[startConference + 3]["final"]["meta"] }}</p>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-container>
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
