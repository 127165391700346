<template>
    <v-card style="width: 100%; margin-top:10px;">
        <v-card-text>
            <v-row no-gutters>
                <v-col md="3">
                    <v-avatar color="primary" size="180">
                        <v-img :src="this.avatarURL"></v-img>
                    </v-avatar>
                    <v-list-item color="rgba(0, 0, 0, .4)" dark>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">
                                    {{ this.selectedStudent.Nachname }}, {{ this.selectedStudent.Vorname }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item>
                    <v-list-item v-if="isEmpty(absents)">
                        <v-progress-circular indeterminate color="primary"/>
                    </v-list-item>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">Fehlstunden {{
                                    this.selectedSubject.FachName
                                }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: .75rem; margin-bottom: 12px;">
                                ({{ this.$moment(this.absentStartDate, "DD-MM-YYYY").format("DD.MM.YYYY") }} -
                                {{ this.$moment(this.absentEndDate, "YYYY-MM-DD").format("DD.MM.YYYY") }})
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>Gesamt: {{ this.absents.ges }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Anwesend: {{ this.absents.Anwesend }} ({{
                                    this.absents.prozentA
                                }}%)
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>Entschuldigt: {{ this.absents.FsE }} ({{
                                    this.absents.prozentE
                                }}%)
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>Unentschuldigt: {{ this.absents.FsUE }} ({{
                                    this.absents.prozentU
                                }}%)
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <p class="primary--text m-3" v-if="!gradesOpen">
                        Die Notenabgabe für die Leistungsbeurteilungen und Halbjahre ist gesperrt, solange die
                        Verwaltung dies
                        nicht explizit freigibt.<br>
                        Die Abgabe der Quartalsnoten für Klausuren, mündliche und sonstige Leistungen ist jederzeit
                        möglich.
                    </p>
                </v-col>
                <v-col md="9">
                    <v-container v-if="!showGradeCard">
                        <v-progress-circular indeterminate color="primary"/>
                    </v-container>
                    <v-container v-else>
                        <v-simple-table>
                            <template>
                                <thead>
                                <tr class="gradeHeaders">
                                    <th class="text-left" colspan="6">
                                        1. Leistungsbeurteilung:
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="gradeHeadersSmall">
                                    <td class="text-left" colspan="4">
                                    </td>
                                    <td>
                                        1. Note
                                    </td>
                                    <td>
                                        Ø
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ examTxt }}:</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <v-select
                                            :items="posExamGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference]['exam']"
                                            :return-object="true"
                                            :readonly="blockMinorFirstExam"
                                            @change="updateGrade(subGrades[startConference]['exam'], 'exam', startConference)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference]["exam"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference]['exam']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mündliche/sonstige Leistungen:</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference]['verbal']"
                                            :return-object="true"
                                            @change="updateGrade(subGrades[startConference]['verbal'], 'verbal', startConference)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference]["verbal"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference]['verbal']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Berechnete Note:</td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference]['main']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Erteilte Note:</td>
                                    <td>
                                        <v-select
                                            :items="posLBGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            :return-object="true"
                                            filled
                                            dense
                                            style="margin-top:12px;"
                                            v-model="grades[startConference]['final']"
                                            :readonly="disableMainGrade(startConference)"
                                            @change="updateFinalGrade(grades[startConference]['final'])"
                                        ></v-select>
                                        <p class="metaLine">{{ grades[startConference]["final"]["meta"] }}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="mt-5">
                            <template>
                                <thead>
                                <tr class="gradeHeaders">
                                    <th class="text-left" colspan="6">
                                        1. Halbjahr:
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="gradeHeadersSmall">
                                    <td class="text-left" colspan="3"></td>
                                    <td>1. Note</td>
                                    <td>2. Note</td>
                                    <td>
                                        Ø
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ examTxt }}:</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ subGrades[startConference]["exam"][selectedGradeType] }}</td>
                                    <td>
                                        <v-select
                                            :items="posExamGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 1]['exam']"
                                            :return-object="true"
                                            :readonly="blockMinorSecondExam"
                                            @change="updateGrade(subGrades[startConference + 1]['exam'], 'exam', startConference + 1)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 1]["exam"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 1]['exam']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mündliche/sonstige Leistungen:</td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ subGrades[startConference]["verbal"][selectedGradeType] }}</td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 1]['verbal']"
                                            :return-object="true"
                                            @change="updateGrade(subGrades[startConference + 1]['verbal'], 'verbal', startConference + 1)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 1]["verbal"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 1]['verbal']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Berechnete Note:</td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 1]['main']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Erteilte Note:</td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            :return-object="true"
                                            filled
                                            dense
                                            style="margin-top:12px;"
                                            v-model="grades[startConference + 1]['final']"
                                            :readonly="disableMainGrade(startConference + 1)"
                                            @change="updateFinalGrade(grades[startConference + 1]['final'])"
                                        ></v-select>
                                        <p class="metaLine">{{ grades[startConference + 1]["final"]["meta"] }}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="mt-5" v-if="showSecondLB">
                            <template>
                                <thead>
                                <tr class="gradeHeaders">
                                    <th class="text-left" colspan="6">
                                        2. Leistungsbeurteilung:
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="gradeHeadersSmall">
                                    <td class="text-left" colspan="2"></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">1. Note</td>
                                    <td v-else></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">2. Note</td>
                                    <td v-else></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">3. Note</td>
                                    <td v-else>1. Note</td>
                                    <td>
                                        Ø
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ examTxt }}:</td>
                                    <td></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference]["exam"][selectedGradeType] }}
                                    </td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference + 1]["exam"][selectedGradeType] }}
                                    </td>
                                    <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                    <td>
                                        <v-select
                                            :items="posExamGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 2]['exam']"
                                            :return-object="true"
                                            :readonly="blockMinorThirdExam"
                                            @change="updateGrade(subGrades[startConference + 2]['exam'], 'exam', startConference + 2)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 2]["exam"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 2]['exam']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mündliche/sonstige Leistungen:</td>
                                    <td></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference]["verbal"][selectedGradeType] }}
                                    </td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference + 1]["verbal"][selectedGradeType] }}
                                    </td>
                                    <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 2]['verbal']"
                                            :return-object="true"
                                            @change="updateGrade(subGrades[startConference + 2]['verbal'], 'verbal', startConference + 2)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 2]["verbal"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 2]['verbal']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Berechnete Note:</td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 2]['main']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Erteilte Note:</td>
                                    <td>
                                        <v-select
                                            :items="posLBGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            :return-object="true"
                                            filled
                                            dense
                                            style="margin-top:12px;"
                                            v-model="grades[startConference + 2]['final']"
                                            :readonly="disableMainGrade(startConference + 2)"
                                            @change="updateFinalGrade(grades[startConference + 2]['final'])"
                                        ></v-select>
                                        <p class="metaLine">{{ grades[startConference + 2]["final"]["meta"] }}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-simple-table class="mt-5">
                            <template>
                                <thead>
                                <tr class="gradeHeaders">
                                    <th class="text-left" colspan="6">
                                        {{ secondHalfYearTxt }}:
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="gradeHeadersSmall">
                                    <td class="text-left"></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">1. Note</td>
                                    <td v-else></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">2. Note</td>
                                    <td v-else></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">3. Note</td>
                                    <td v-else-if="selectedClass.full_name.includes('BG') && showSecondLB">1. Note</td>
                                    <td v-else></td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">4. Note</td>
                                    <td v-else-if="selectedClass.full_name.includes('BG') && showSecondLB">2. Note</td>
                                    <td v-else>1. Note</td>
                                    <td>
                                        Ø
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ examTxt }}:</td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference]["exam"][selectedGradeType] }}
                                    </td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference + 1]["exam"][selectedGradeType] }}
                                    </td>
                                    <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                    <td v-if="showSecondLB">{{
                                            subGrades[startConference + 2]["exam"][selectedGradeType]
                                        }}
                                    </td>
                                    <td v-if="!showSecondLB"></td>
                                    <td>
                                        <v-select
                                            :items="posExamGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 3]['exam']"
                                            :return-object="true"
                                            :readonly="blockMinorFourthExam"
                                            @change="updateGrade(subGrades[startConference + 3]['exam'], 'exam', startConference + 3)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 3]["exam"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 3]['exam']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mündliche/sonstige Leistungen:</td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference]["verbal"][selectedGradeType] }}
                                    </td>
                                    <td v-if="!selectedClass.full_name.includes('BG')">
                                        {{ subGrades[startConference + 1]["verbal"][selectedGradeType] }}
                                    </td>
                                    <td v-if="selectedClass.full_name.includes('BG')" colspan="2"></td>
                                    <td v-if="showSecondLB">
                                        {{ subGrades[startConference + 2]["verbal"][selectedGradeType] }}
                                    </td>
                                    <td v-if="!showSecondLB"></td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            v-model="subGrades[startConference + 3]['verbal']"
                                            :return-object="true"
                                            @change="updateGrade(subGrades[startConference + 3]['verbal'], 'verbal', startConference + 3)"
                                        ></v-select>
                                        <p class="metaLine">{{ subGrades[startConference + 3]["verbal"]["meta"] }}</p>
                                    </td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 3]['verbal']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Berechnete Note:</td>
                                    <td>
                                        <v-text-field
                                            readonly
                                            v-model="averages[startConference + 3]['main']"
                                        ></v-text-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left" colspan="5">Erteilte Note:</td>
                                    <td>
                                        <v-select
                                            :items="posGrades"
                                            label="Note auswählen..."
                                            :item-text="selectedGradeType"
                                            item-value="IDNotenZensur"
                                            single-line
                                            :return-object="true"
                                            filled
                                            dense
                                            style="margin-top:12px;"
                                            v-model="grades[startConference + 3]['final']"
                                            :readonly="disableMainGrade(startConference + 3)"
                                            @change="updateFinalGrade(grades[startConference + 3]['final'])"
                                        ></v-select>
                                        <p class="metaLine">{{ grades[startConference + 3]["final"]["meta"] }}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";
import {
    calculateBGAverages,
    calculateFOSAverages,
    calculateGymRealAverages,
    calculateStartConference
} from "@/plugins/SubGradeHelper";
import {isEmpty} from "lodash";

@Component({
    methods: {isEmpty}
})
export default class SubGradeGrid extends Vue {
    @Prop() selectedClass!: Record<string, any>;
    @Prop() selectedSubject!: Record<string, any>;
    @Prop() selectedStudent!: Record<string, any>;
    @Prop() gradesOpen!: boolean;
    @Prop() avatarURL!: String;
    @Prop() posGrades!: Array<Record<string, any>>;
    @Prop() posExamGrades!: Array<Record<string, any>>;
    @Prop() posLBGrades!: Array<Record<string, any>>;
    @Prop() absentStartDate!: string;

    private showGradeCard = false;
    private subGrades: any = null;
    private grades: any = null;
    private absents: Record<any, any> = {};
    private averages: Array<any> = [];
    private bg11MinorCourse = false;
    private blockMinorFirstExam = false;
    private blockMinorSecondExam = false;
    private blockMinorThirdExam = false;
    private blockMinorFourthExam = false;
    private absentEndDate: any = null;
    private showSecondLB = true;
    private examTxt: String = "Klausur";
    private secondHalfYearTxt: String = "Jahrgangszeugnis";
    private selectedGradeType: string = "Punkte";
    private selectedConference: any = null;
    private startConference = 1;

    private http = useHttp();
    private auth = useAuth();

    private async mounted() {
        this.setGradeType();
        this.prepareLayout();
        await this.prepareStudent();
        this.loadSubGrades();
    }

    private updateGrade(item: any, type: string, conference: number) {
        const payload = {
            subject_id: this.selectedSubject.IDFaecher,
            grade_id: item.IDNotenZensur,
            teacher_id: this.auth.user().id,
            grade_type: type,
            conference_id: conference,
        };

        this.http.post(`subgrades/${this.selectedStudent.IDSchueler}`, payload).then(() => {
            this.calculateAverages();
        });

    }

    public async updateFinalGrade(grade: any) {
        const payload = {
            FachID: this.selectedSubject.IDFaecher,
            grade: grade.IDNotenZensur,
            IDLehrer: this.auth.user().id
        };

        await this.http.post(`grades/${this.selectedStudent.IDSchueler}`, payload);
    }

    private calculateAverages() {

        this.averages = [];

        if (this.selectedClass.full_name.includes("BG")) {
            // bg 11 minor/mayor exam regulation
            this.blockBGExams();
            // calculate averages
            this.averages = calculateBGAverages(this.subGrades, this.startConference, this.selectedClass, this.bg11MinorCourse);
        }

        if (this.selectedClass.full_name.includes("FOS")) {
            this.averages = calculateFOSAverages(this.subGrades, this.startConference);
        }

        if (this.selectedClass.full_name.includes("Gym") || this.selectedClass.full_name.includes("Real")) {
            let mainCourses = ["Mathematik", "Deutsch", "Englisch", "Spanisch"];
            let minorCourse = !mainCourses.some(el => this.selectedSubject.FachName.includes(el));
            this.averages = calculateGymRealAverages(this.subGrades, this.startConference, minorCourse);
        }
    }

    private blockBGExams() {

        //reset
        this.blockMinorFirstExam = false;
        this.blockMinorSecondExam = false;
        this.blockMinorThirdExam = false;
        this.blockMinorFourthExam = false;
        if (this.selectedClass.full_name.includes("BG 11")) {
            if (this.bg11MinorCourse) {
                if (!isNaN(this.subGrades[this.startConference].exam[this.selectedGradeType])) {
                    // first quarter has exam
                    this.blockMinorSecondExam = true;
                }

                if (!isNaN(this.subGrades[this.startConference + 1].exam[this.selectedGradeType])) {
                    // second quarter has exam
                    this.blockMinorFirstExam = true;
                }

                if (!isNaN(this.subGrades[this.startConference + 2].exam[this.selectedGradeType])) {
                    // fourth quarter has exam
                    this.blockMinorFourthExam = true;
                }

                if (!isNaN(this.subGrades[this.startConference + 3].exam[this.selectedGradeType])) {
                    // third quarter has exam
                    this.blockMinorThirdExam = true;
                }
            }
        }
    }

    private loadSubGrades() {

        // reset
        this.bg11MinorCourse = false;

        // for class BG 11 the average calculation and number of exam differs between mayor and minor courses
        if (this.selectedClass.full_name.includes("BG 11")) {
            let mainCourses = ["Mathematik", "Deutsch", "Englisch", "Spanisch"];

            if (this.selectedClass.full_name.includes("W")) {
                mainCourses.push("Wirtschaftslehre");
            }
            if (this.selectedClass.full_name.includes("G")) {
                mainCourses.push("Gesundheitslehre");
            }

            this.bg11MinorCourse = !mainCourses.some(el => this.selectedSubject.FachName.includes(el));
        }

        // load grades
        if (!isNaN(this.selectedSubject.IDFaecher) && !isNaN(this.selectedStudent.IDSchueler)) {
            this.http.get(`subgrades/${this.selectedStudent.IDSchueler}/${this.selectedSubject.IDFaecher}`)
                .then((data: any) => {
                    this.subGrades = data.subGrades;
                    this.grades = data.grades;
                    this.calculateAverages();
                }).then(() => this.showGradeCard = true);

            // load absent times
            this.getAbsentTimes();
        }
    }

    private disableMainGrade(conference: number) {
        return !(this.gradesOpen && conference == this.selectedConference.IDKonferenz);
    }

    private async getAbsentTimes() {
        this.absentEndDate = this.$moment().format("YYYY-MM-DD");

        this.http.get(`/absent/student/${this.selectedStudent.IDSchueler}/${this.absentStartDate}/${this.absentEndDate}`).then((data: any) => {
            if (data && data.subjects[this.selectedSubject.IDFaecher]) {
                this.absents = data.subjects[this.selectedSubject.IDFaecher];
            } else {
                this.absents = {
                    ges: 0,
                    Anwesend: 0,
                    FsE: 0,
                    FsUE: 0,
                    prozentA: 0,
                    prozentE: 0,
                    prozentU: 0
                };
            }
        });
    }

    private prepareLayout() {
        // Change text for Klausur if class is BG
        this.examTxt = "Klausur/Leistungsnachweis";
        if (this.selectedClass.full_name.includes("BG")) {
            this.secondHalfYearTxt = "2. Halbjahr";

            // BG 13 does not have an 2. LB
            if (this.selectedClass.full_name.includes("BG 13")) {
                this.showSecondLB = false;
            }
        } else {
            this.secondHalfYearTxt = "Jahrgangszeugnis";
        }
    }

    private async prepareStudent() {
        // load actual conference for the student, could be different from class-conference
        this.selectedConference = await this.http.get(`/student/${this.selectedStudent.IDSchueler}/active-grade-conference`);

        if (this.selectedConference.IDKonferenz === undefined) {
            this.$swal.fire({
                title: `Für ${this.selectedStudent.Vorname} konnte keine aktuelle Notenkonferenz ermittelt werden. Die Verwaltung wurde informiert. Bitte versuchen Sie es später erneut.`,
                confirmButtonText: "Okay"
            });
        }

        this.startConference = calculateStartConference(this.selectedConference.IDKonferenz, this.selectedClass);
    }

    private setGradeType() {
        let classFullName = this.selectedClass.full_name;

        if (["FOS", "BOS", "BG"].some(name => classFullName.includes(name))) {
            this.selectedGradeType = "Punkte";
        } else {
            this.selectedGradeType = "Note";
        }
    }
}

</script>

<style lang="scss" scoped>

.gradeHeaders th {
    font-size: 1.2rem !important;
    color: #2196f3 !important;
}

.gradeHeadersSmall td {
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    color: #2196f3 !important;
}

.metaLine {
    font-size: .75rem !important;
    color: #686868 !important;
}

</style>
