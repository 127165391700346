<template>
  <v-app>
    <notifications/>
    <v-navigation-drawer
        v-model="drawer"
        app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar size="60" style="margin-left: auto; margin-right: auto">
            <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ $auth.user().name }}
              {{ $auth.user().lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ $auth.user().email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.link"
            link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/grades" v-if="grades">
          <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Notentool</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/gradesWorkSocial" v-if="arbSoz">
          <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Arbeit.- & Soz.-verhalten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/gradesExam" v-if="exam">
          <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Prüfungsnoten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/subGrades" v-if="quarterGrades">
          <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Notenrechner</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openInNewTab(cloudLink)" v-if="cloudLink">
          <v-list-item-icon>
            <v-icon>mdi-cloud</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Rackow Cloud (alt)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openInNewTab('https://outlook.office.com/mail')">
          <v-list-item-icon>
            <v-icon>mdi-email-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Outlook Web</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openInNewTab('https://teams.microsoft.com')">
          <v-list-item-icon>
            <v-icon>mdi-microsoft-teams</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>MS Teams</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openInNewTab('https://portal.office.com/onedrive')">
          <v-list-item-icon>
            <v-icon>mdi-microsoft-onedrive</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>MS OneDrive</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="openSupportDialog">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Support
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Support Anfrage</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <p class="text-body-1">
                  Dieses Formular dient dazu, Tickets für die IT Abteilung der Schule zu erstellen. Hierbei können
                  sämtliche Probleme in den Räumen geschildert werden. Dazu zählen Probleme mit der
                  Technik (Software & Hardware) sowie Probleme im Klassenraum selbst, z.B. Klimaanlage oder Rollos.
                </p>
                <v-form>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                          label="Raum"
                          v-model="form.room_id"
                          :items="rooms"
                          item-text="Name"
                          item-value="IDRaum"
                          no-data-text="Daten werden geladen..."
                          :error-messages="roomErrors"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                          label="Kategorie"
                          v-model="form.category_id"
                          :items="ticketCategories"
                          item-text="name"
                          item-value="id"
                          no-data-text="Daten werden geladen..."
                          :error-messages="categoryErrors"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="text-body-1">
                        Bitte beschreiben Sie in der unteren Textbox das Problem möglichst genau, sodass sich unsere IT
                        Abteilung schnellstens drum kümmern kann.
                      </p>
                      <v-textarea
                          name="content"
                          filled
                          label="Beschreibung"
                          auto-grow
                          rows="10"
                          v-model="form.content"
                          :error-messages="contentErrors"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="accent"
                  @click="dialog = false">
                Abbrechen
              </v-btn>
              <v-btn
                  color="primary"
                  :disabled="disableTicketBtn"
                  @click="createTicket">
                Absenden
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app style="z-index: 999">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>RACKOW DKB</v-toolbar-title>
    </v-app-bar>

    <v-main class="flex fill-height" style="background-color: lightgrey">
      <!-- Provides the application the proper gutter -->

      <!-- If using vue-router -->
      <!--<keep-alive :include="cachingComponents">-->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useForm} from "@/core/form";
import {useAuth} from "@/plugins/auth";

@Component
export default class App extends Vue {
  private drawer = true;
  private user = this.$store.getters["getUser"];
  private grades = false;
  private arbSoz = false;
  private exam = false;
  private quarterGrades = false;
  private disableTicketBtn = false;
  private cloudLink = <string>import.meta.env.VITE_CLOUD_LINK ?? "";
  private items = [
    {title: "Dashboard", icon: "mdi-view-dashboard", link: "/dashboard"},
    {title: "Stundenplan", icon: "mdi-menu", link: "/timetable"},
    {title: "Stundeninhalte", icon: "mdi-menu", link: "/contents"},
    {title: "Fehlzeiten", icon: "mdi-clock-outline", link: "/absents"},
    {
      title: "HA Betreuung",
      icon: "mdi-account-multiple-outline",
      link: "/homework",
    },
    {
      title: "HA Betreuung (verpflichten)",
      icon: "mdi-account-multiple-outline",
      link: "/homework-create",
    },

    {title: "Dokumente", icon: "mdi-file", link: "/documents"},
  ];
  private dialog = false;
  private form = useForm({
    room_id: undefined,
    creator_id: undefined,
    category_id: undefined,
    content: undefined
  })

  private rooms: Array<any> = []
  private ticketCategories: Array<any> = []
  private roomErrors: Array<any> = []
  private categoryErrors: Array<any> = []
  private contentErrors: Array<any> = []

  private http = useHttp()
  private auth = useAuth()

  private async mounted() {
    this.http.get(`grades/open/${this.auth.user().id}`).then((data: any) => {
      this.grades = data['grades'];
      this.arbSoz = data['arbsoz'];
      this.exam = data['exams'];
      this.quarterGrades = data['quarterGrades'];
    })
    this.getTicketAndCategories()
  }

  public openInNewTab(url: string) {
    window.open(url, '_blank');
  }

  public logout() {
    this.$notify({
      title: "Abgemeldet",
      type: "success",
      text: "Du bist abgemeldet",
    });
    this.$router.push({name: 'logout'});
  }

  // Sends a POST request to the backend to create a ticket and shows a sweet alert if successfully.
  private async createTicket() {
    this.disableTicketBtn = true
    if (this.validateTicketForm()) {
      this.form.creator_id = this.auth.user().id
      await this.form.usingSubmit(useHttp().post(`tickets`, this.form.data())).then(() => {
        this.$swal('Erfolgreich', 'Anfrage erfolgreich versendet.', 'success');
        this.form.reset()
        this.disableTicketBtn = false
        this.dialog = false
      })
    } else {
      this.disableTicketBtn = false
    }
  }

  // Opens the support dialog and checks if the rooms and categories were already retrieved. If not, the
  // corresponding request is made.
  private openSupportDialog() {
    if (!this.rooms && !this.ticketCategories) {
      this.getTicketAndCategories()
    }
    this.dialog = true
  }

  // Makes a request to the server and retrieves all available rooms and ticket categories.
  private getTicketAndCategories() {
    this.http.get(`tickets/rooms-categories`).then((data: any) => {
      this.rooms = data.rooms.map(function (room: any) {
        room.Name = 'Raum ' + room.Name
        return room
      })
      this.ticketCategories = data.categories
    })
  }

  // Validates the form to create a ticket.
  private validateTicketForm() {
    this.roomErrors = []
    this.categoryErrors = []
    this.contentErrors = []

    if ((this.form.room_id || (!this.form.room_id && this.form.category_id === 5))
        && this.form.category_id !== undefined
        && this.form.content) {
      return true
    }

    if (!this.form.room_id && this.form.category_id !== 5) {
      this.roomErrors.push('Der Raum muss angegeben werden.')
    }

    // Normal check for undefined cannot be used because id of first category is zero and would cause error message.
    if (this.form.category_id === undefined) {
      this.categoryErrors.push('Die Kategorie muss angegeben werden.')
    }

    if (!this.form.content) {
      this.contentErrors.push('Das Problem muss beschrieben werden.')
    }

    return false
  }
}
</script>
