
<v-container fluid>
    <v-card style="width: 100%">
        <v-card-text>
            <v-item-group
                class="flex row ml-6 mt-1"
                flex
                style="align-content: space-between"
            >
                <v-autocomplete
                    v-model="selectedClass"
                    :items="classes"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-text="full_name"
                    item-value="IDArtSchule"
                    label="Klasse"
                    no-data-text="Laden..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 160px"
                />
                <v-autocomplete
                    v-if="subjects.length > 0"
                    v-model="selectedSubject"
                    :items="subjects"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-text="FachName"
                    item-value="IDFaecher"
                    label="Fach"
                    no-data-text="Laden..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 160px"
                />
            </v-item-group>
        </v-card-text>
    </v-card>
    <br/>
    <v-card v-if="grades">
        <v-data-table
            :headers="headers"
            :items="grades.students"
            :loading="loading"
            loading-text="Laden..."
            no-data-text="Keine Daten gefunden"
            class="elevation-1"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:[`item.IDSchueler`]="{ item }">
                <v-avatar class="ml-3">
                    <v-img
                        :src="getAvatar(item)"
                        lazy-src="/images/no-avatar.jpg"
                        max-height="48"
                        max-width="48"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <strong>{{ getFullName(item) }}</strong>
                <br/>
                <small
                ><strong>{{
                        $moment().diff(item.GebDatum, "years", false) >= 18
                            ? "Volljährig"
                            : "Minderjährig"
                    }}</strong></small
                >
            </template>
            <template v-slot:[`item.leistung`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.Leistung"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'Leistung')"
                ></v-select>
            </template>
            <template v-slot:[`item.zuver`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.zuverlaessigkeit"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'zuverlaessigkeit')"
                ></v-select>
            </template>
            <template v-slot:[`item.selbst`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.selbststaendigkeit"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'selbststaendigkeit')"
                ></v-select>
            </template>
            <template v-slot:[`item.verant`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.verantwortung"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'verantwortung')"
                ></v-select>
            </template>
            <template v-slot:[`item.team`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.team"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'team')"
                ></v-select>
            </template>
            <template v-slot:[`item.respeckt`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.respeckt"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'respeckt')"
                ></v-select>
            </template>
            <template v-slot:[`item.arbeit`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.arbeitsmaterial"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'arbeitsmaterial')"
                ></v-select>
            </template>
            <template v-slot:[`item.digi`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.digi"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Bewertung"
                    single-line
                    @change="updateGrade(item, 'digi')"
                ></v-select>
            </template>
            <template v-slot:[`item.work_grade_id`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.work_grade_id"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Note"
                    single-line
                    @change="updateGrade(item, 'work_grade_id')"
                ></v-select>
                <p class="meta">{{ item.selectedGrade.work_grade_meta }}</p>
            </template>
            <template v-slot:[`item.social_grade_id`]="{ item }">
                <v-select
                    v-model="item.selectedGrade.social_grade_id"
                    :item-text="selectedGradeType"
                    :items="posGrades"
                    :return-object="true"
                    item-value="IDNotenZensur"
                    label="Note"
                    single-line
                    @change="updateGrade(item, 'social_grade_id')"
                ></v-select>
                <p class="meta">{{ item.selectedGrade.social_grade_meta }}</p>
            </template>
        </v-data-table>
    </v-card>
    <br>
    <v-card v-if="grades">
        <v-btn
            class="ma-3"
            color="primary"
            @click="saveNothing"
        >Bewertung abgeben
        </v-btn>
    </v-card>
</v-container>
