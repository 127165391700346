
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between"
      >
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 160px"
            :items="classes"
            item-text="full_name"
            item-value="IDFaecher"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="mr-2"
            no-data-text="Loading..."
            clearable
        />
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 160px"
            :items="subjects"
            item-text="FachName"
            item-value="IDFaecher"
            label="Fächer"
            :return-object="true"
            v-model="selectedSubject"
            class="mr-2"
            no-data-text="Loading..."
            :disabled="!subjects.length"
            clearable
        />
      </v-item-group>
    </v-card-text>
  </v-card>
  <br/>
  <v-data-table
      :headers="headers"
      :items="contents"
      :items-per-page="15"
      class="elevation-1"
      :loading="loading"
      disable-pagination
      :hide-default-footer="true"
  >
    <template v-slot:[`item.Datum`]="{ item }">
      {{ item.Datum | moment("DD.MM.YYYY") }}
    </template>
  </v-data-table>
</v-container>
