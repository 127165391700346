<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  /**
   * Component constructor.
   *
   * @param props
   */
  setup(props) {
    return {}
  }
})
</script>
