<template>
    <v-container fluid>
        <v-card style="width: 100%" :loading="loading">
            <v-card-text>
                <ClassStudentSubjectSelect
                    @updateClass="this.updateClass"
                    @updateStudent="this.updateStudent"
                    @updateSubject="this.updateSubject"
                    @toggleLoading="this.toggleLoading"/>
            </v-card-text>
        </v-card>

        <SubGradeGrid :key="forceRerender"
                      :gradesOpen="gradesOpen"
                      :selectedClass="studentClass"
                      :selectedStudent="student"
                      :selectedSubject="studentSubject"
                      :avatarURL="avatar"
                      :posGrades="posGrades"
                      :posExamGrades="posExamGrades"
                      :posLBGrades="posLBGrades"
                      :absentStartDate="absentStartDate"
                      v-if="dataIsComplete"/>
    </v-container>
</template>


<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import ClassStudentSubjectSelect from "@/components/ClassStudentSubjectSelect.vue";
import SubGradeGrid from "@/components/SubGradeGrid.vue";
import {isEmpty} from "lodash";

@Component({
    components: {
        SubGradeGrid,
        ClassStudentSubjectSelect
    }
})
export default class SubGrades extends Vue {
    private loading: boolean = false;
    private studentClass: Record<string, any> = {};
    private studentSubject: Record<string, any> = {};
    private student: Record<string, any> = {};
    private gradesOpen = false;
    private dataIsComplete: boolean = false;
    private forceRerender: number = 1;
    private avatar: String = "";
    private grades: Array<Record<string, any>> = [];
    private gradesWithoutStar: Array<Record<string, any>> = [];
    private gradesWithoutTendencies: Array<Record<string, any>> = [];
    private posGrades: Array<Record<string, any>> = [];
    private posLBGrades: Array<Record<string, any>> = [];
    private posExamGrades: Array<Record<string, any>> = [];
    private absentStartDate: string = "";

    private http = useHttp();

    private async mounted() {
        // load possible grades
        this.http.get("grades/possible")
            .then((data: any) => {
                this.grades = data;
                this.gradesWithoutStar = this.grades.filter((grade: Record<string, any>) => grade.Note !== "*");
                this.gradesWithoutTendencies = this.gradesWithoutStar.filter(grade => grade.Note == grade.NoteoT || grade.Note === "-");
                this.posExamGrades = this.grades;
                this.posGrades = this.gradesWithoutStar;
                this.posLBGrades = this.gradesWithoutStar;
            });

        this.absentStartDate = await this.http.get("absent/startdate");
    }

    private updateClass(value: Record<string, any>) {
        this.studentClass = value;
        this.gradesOpen = value.opened_grade_tool;
        this.avatar = "";
        this.updatePossibleGrades();
        this.checkDataIsComplete();
    }

    private updateStudent(value: Record<string, any>) {
        this.student = value;
        this.avatar = "";
        this.checkDataIsComplete();
        this.loadAvatar();
    }

    private updateSubject(value: Record<string, any>) {
        this.studentSubject = value;
        this.checkDataIsComplete();
    }

    private toggleLoading(state: boolean) {
        this.loading = state;
    }

    private checkDataIsComplete() {
        this.dataIsComplete = !isEmpty(this.studentClass) && !isEmpty(this.student) && !isEmpty(this.studentSubject);
        this.forceRerender++;
    }

    // needs to be here, to store the url in a place which not reloads or gets remounted
    private loadAvatar() {
        // load avatar
        if (!isEmpty(this.student)) {
            this.http.get(`/student/${this.student.IDSchueler}/avatar`)
                .then((data: any) => {
                    this.avatar = data;
                });
        }
    }

    private updatePossibleGrades() {
        this.posLBGrades = this.gradesWithoutStar;

        let primaryClasses = ["Real", "Gym"];
        if (primaryClasses.some(el => this.studentClass.Klassen.includes(el))) {
            this.posGrades = this.gradesWithoutTendencies;
            this.posExamGrades = this.grades.filter(grade => grade.Note == grade.NoteoT || grade.Note === "-");
        } else {
            this.posGrades = this.gradesWithoutStar;
            this.posExamGrades = this.grades;
        }
    }
}
</script>

