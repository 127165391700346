
<div>
  <v-card :loading="loading">
    <v-card-title>Anwesenheit</v-card-title>
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between">
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 160px"
            :items="classes"
            item-text="full_name"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="mr-2"
            no-data-text="Laden..."
            clearable/>
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 320px"
            :items="weeks"
            item-text="text"
            item-value="kw"
            label="Kalenderwoche"
            :return-object="true"
            v-model="selectedWeek"
            class="mr-2"
            no-data-text="Laden..."
            :disabled="!(selectedClass && selectedClass.IDArtSchule)"/>
      </v-item-group>
    </v-card-text>
  </v-card>
  <br/>
  <v-card>
    <v-card-text v-show="selectedClass && selectedClass.IDArtSchule && selectedWeek.start">
      <div style="overflow: scroll; color: #fff">
        <div style="width: 2200px; height: 70vh">
          <table style="width: 100%">
            <thead style="font-weight: bold">
            <tr>
              <th class="sticky-both">
                <div class="table-header">Schülername</div>
              </th>
              <th class="sticky-row"
                  v-for="weekday in weekdays"
                  v-bind:key="weekday">
                <div class="table-header">{{ weekday }}</div>
                <div class="item"
                     v-for="colNumber in lessons"
                     v-bind:key="colNumber">
                  {{ colNumber }}
                </div>
              </th>
            </tr>
            </thead>
            <tbody style="border: 1px solid">
            <tr v-for="item in orderedTimes()" v-bind:key="item.id+'-row'">
              <td class="sticky-col" style="padding-left: 0.5rem">
                {{ item.nachname }}, {{ item.vorname }}
              </td>
              <td v-for="(times, idx) in item.times" v-bind:key="item.id+'weekday-'+idx">
                <div
                    class="item"
                    v-for="(day, idx) in times"
                    style="font-weight: bold;"
                    v-bind:key="item.id+'-lesson-'+idx"
                    v-bind:style="{ backgroundColor: colors[day] }">
                  <span v-if="day === 'false'">*</span>
                  <span v-else>{{ day }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</div>
