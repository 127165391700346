
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group>
        <v-item>
          <v-row>
            <v-col cols="9"
            ><h4>
              Lehrer: {{ `${this.auth.user().lastName} ${this.auth.user().name}` || "Keine Daten" }} | Klasse:
              {{ data.className || "lade..." }} | Fachname:
              {{ data.subjectName || "lade..." }} | Zeit:
              {{ this.block || "lade..." }}. Stunde
            </h4></v-col
            >
          </v-row>
        </v-item>
      </v-item-group>
    </v-card-text>
  </v-card>
  <v-card class="mt-5" style="width: 100%">
    <v-data-table
        :headers="courseContentHeaders"
        :items="data.courseContent"
        :items-per-page="5"
        class="elevation-1"
        :loading="loading"
    >
      <template v-slot:[`item.Datum`]="{ item }">
        {{ item.Datum | moment("DD.MM.YYYY") }}
      </template>
    </v-data-table>
  </v-card>
  <br/>
  <v-data-table
      :headers="headers"
      :items="data.students"
      disable-pagination
      hide-default-footer
      class="elevation-1"
      :loading="loading"
  >
    <template v-slot:header="{ props, on }">
      <thead>
      <tr>
        <th>{{ data.students ? data.students.length : 0 }} Schüler</th>
        <th>
          <v-checkbox
              class="mt-2 mb-2 pa-0 font-weight-regular"
              label="Geimpft anzeigen"
              v-model="covidVacLabel"
              hide-details
          ></v-checkbox>
        </th>
        <th></th>
        <th>
          <v-btn
              small
              width="100%"
              :color="groupExistence !== '' ? 'primary' : 'default'"
              @click="touchGroupExistence"
              :disabled="loading"
              :loading="loading"
          >
            {{ groupExistenceTitle }}
          </v-btn>
        </th>
        <th>
          <template v-for="(group, i) in groups">
            <v-btn
                small
                :color="groupStatusColor(group)"
                :class="{ 'ml-2':  i > 0 }"
                @click="touchGroupStatus(group)"
                :key="group"
                :disabled="loading"
                :loading="loading"
            >
              Grp. {{ group }}
            </v-btn>
          </template>
        </th>
        <th></th>
      </tr>
      </thead>
    </template>

    <template v-slot:[`item.Datum`]="{ item }">
      {{ item.Datum | moment("DD.MM.YYYY") }}
    </template>

    <template v-slot:[`item.IDSchueler`]="{ item }">
      <v-avatar class="ml-3">
        <v-img
            lazy-src="/images/no-avatar.jpg"
            :src="getAvatar(item)"
            max-width="48"
            max-height="48"
        ></v-img>
      </v-avatar>
    </template>
    <template v-slot:[`item.beLate`]="{ item }">
      <strong>{{ getBeLate(item) }} Verspätung(en)</strong>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <strong>{{ getFullName(item) }}</strong>
      <br/>
      <small
      ><strong>{{
          $moment().diff(item.GebDatum, "years", false) >= 18
              ? "Volljährig"
              : "Minderjährig"
        }}</strong></small
      >
      <br/>
      <small> Gruppe {{ getGruppe(item) }}</small>
    </template>

    <template v-slot:[`item.SchulArtGruppe`]="{ item }">
      <v-switch
          v-model="item.online"
          :disabled="!item.existence || item.existence === 'E'"
          @change="updateExistence(item)"
          :label="`Online (Gruppe ${item.SchulArtGruppe})`"
          v-if="!isBlocked(item.IDSchueler.toString())"/>
      <h2 class="py-10" v-else-if="item.existence === 'F'">Hat sich im Sekretariat abgemeldet</h2>
      <h2 class="py-10" v-else>Entschuldigung liegt vor</h2>
    </template>

    <template v-slot:[`item.leave`]="{ item }">
      <v-btn
          class="mb-5"
          v-if="!isBlocked(item.IDSchueler.toString())"
          :disabled="item.existence !== 'A' && item.existence !== 'V'"
          depressed
          @click="updateExistence(item, 'G')">
        vorzeitiges Verlassen
      </v-btn>
    </template>

    <template v-slot:[`item.existence`]="{ item }">
      <v-radio-group
          v-model="item.existence"
          v-if="!isBlocked(item.IDSchueler.toString())"
          @change="updateExistence(item)">
        <!-- sonderfall E -->
        <v-radio label="Anwesend" value="A"></v-radio>
        <v-radio label="Fehlend" value="F"></v-radio>
        <v-radio label="Verspätet" value="V"></v-radio>
      </v-radio-group>
      <v-icon large v-else>mdi-emoticon-sick-outline</v-icon>
    </template>
  </v-data-table>
  <v-card style="width: 100%; margin-top: 1.5rem">
    <v-card-text>
      <v-item-group>
        <v-item>
          <v-row>
            <v-col cols="9"
            ></v-col>
            <v-col cols="3">
              <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      v-bind="attrs" v-on="on"
                  >Senden & Stundeninhalt hinzufügen
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar color="primary" dark>Stunde abschließen</v-toolbar>
                    <v-card-text class="pb-0">
                      <p class="pt-4 text-h6"> Es wurden {{ countExistence() }} von
                        {{ data.students ? data.students.length : 0 }} Schülern eingetragen.</p>
                      <div class="text-h2 pt-4 pb-3">
                        <v-textarea
                            v-model="lessonContent"
                            rows="6"
                            filled
                            hide-details
                            required
                        ></v-textarea>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                          @click="saveContent(dialog);"
                      >Speichern
                      </v-btn>
                      <v-btn
                          text
                          @click="dialog.value = false"
                      >Schließen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
        </v-item>
      </v-item-group>
    </v-card-text>
  </v-card>
  <v-dialog
      v-model="dialog"
      persistent
      width="500">
    <v-card>
      <v-card-title class="text-h5 lighten-2">
        Untergruppen
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text
          class="mt-3"
      >
        Ihnen wurden für diese Klasse mehrere Untergruppen für dasselbe Fach zugewiesen. Welche Untergruppe
        unterrichten Sie gerade?

        <v-select
            :items="subGroups"
            v-model="selectedSubGroup"
            label="Untergruppen"
            class="mt-3"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="filterForSubGroups()"
        >
          Anzeigen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
