
<v-container fluid fill-height>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>DKB Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
                prepend-icon="mdi-login"
                name="login"
                label="Benutzername"
                type="text"
                v-model="form.username"
                v-on:keyup.enter="login"
                :error-messages="form.errors.get('username')"
            ></v-text-field>
            <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Passwort"
                type="password"
                v-on:keyup.enter="login"
                v-model="form.password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" to="/" @click="login">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
