<template>
    <v-container fluid>
        <v-card style="width: 100%">
            <v-card-text>
                <v-item-group
                    flex
                    class="flex row ml-6 mt-1"
                    style="align-content: space-between"
                >
                    <v-autocomplete
                        prepend-inner-icon="mdi-magnify"
                        style="max-width: 160px"
                        :items="classes"
                        item-text="full_name"
                        item-value="IDFaecher"
                        :return-object="true"
                        label="Klasse"
                        v-model="selectedClass"
                        class="mr-2"
                        no-data-text="Loading..."
                        clearable
                    />
                    <v-autocomplete
                        prepend-inner-icon="mdi-magnify"
                        style="max-width: 160px"
                        :items="subjects"
                        item-text="FachName"
                        item-value="IDFaecher"
                        label="Fächer"
                        :return-object="true"
                        v-model="selectedSubject"
                        class="mr-2"
                        no-data-text="Loading..."
                        :disabled="!subjects.length"
                        clearable
                    />
                </v-item-group>
            </v-card-text>
        </v-card>
        <br/>
        <v-card v-if="grades">
            <v-data-table
                :headers="headers"
                :items="grades.students"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                :loading="loading"
            >
                <template v-slot:[`item.IDSchueler`]="{ item }">
                    <v-avatar class="ml-3">
                        <v-img
                            lazy-src="/images/no-avatar.jpg"
                            :src="getAvatar(item)"
                            max-width="48"
                            max-height="48"
                        ></v-img>
                    </v-avatar>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <strong>{{ getFullName(item) }}</strong>
                    <br/>
                    <small>
                        <strong>
                            {{
                                $moment().diff(item.GebDatum, "years", false) >= 18
                                    ? "Volljährig"
                                    : "Minderjährig"
                            }}
                        </strong>
                        <strong
                            v-if="selectedClass.full_name.includes('IBA')"
                            class="ml-2"
                            :class="item.iba_niveau.includes('GR') ? 'error--text' : ''">
                            ({{ item.iba_niveau }})
                        </strong>
                    </small
                    >
                </template>
                <template v-slot:[`item.grade`]="{ item }">
                    <v-select
                        :items="posGrades"
                        label="Noten"
                        :item-text="selectedGradeType"
                        item-value="IDNotenZensur"
                        single-line
                        :return-object="true"
                        v-model="item.selectedGrade"
                        @change="updateGrade(item)"
                    ></v-select>
                </template>
                <template v-slot:[`item.ibaGradeEBBR`]="{ item }">
                    <v-select
                        :items="posGrades"
                        label="Noten"
                        :item-text="selectedGradeType"
                        item-value="IDNotenZensur"
                        single-line
                        :return-object="true"
                        v-model="item.selectedGradeEbbr"
                        @change="updateGrade(item, 'eBBR')"/>
                </template>
                <template v-slot:[`item.ibaGradeMSA`]="{ item }">
                    <v-select
                        :items="posGrades"
                        label="Noten"
                        :item-text="selectedGradeType"
                        item-value="IDNotenZensur"
                        single-line
                        :return-object="true"
                        v-model="item.selectedGradeMsa"
                        @change="updateGrade(item, 'MSA')"/>
                </template>
            </v-data-table>
        </v-card>
        <br>
        <v-card v-if="grades">
            <v-btn
                class="ma-3"
                color="primary"
                @click="sendPDF"
            >Notenübersicht versenden
            </v-btn>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";

@Component
export default class GradesExam extends Vue {
    private classes: Array<any> = [];
    private subjects: Array<any> = [];
    private loading = false;
    private grades: any = null;
    private posGrades: Array<any> = [];
    private selectedClass: Record<string, any> = {};
    private selectedSubject: Record<string, any> = {};
    private selectedGradeType: string = "Punkte";

    private homeworkSubjects: Array<number> = [6, 8, 12, 14, 81, 132];

    private headers: Array<Record<string, any>> = [];

    private http = useHttp();
    private auth = useAuth();

    @Watch("selectedClass")
    private async classUpdater() {
        // get objects for class
        this.selectedSubject = {};
        this.loading = true;

        this.http.get(`/grades/exam/${this.selectedClass.IDArtSchule}/subjects`)
            .then((data: any) => {
                this.grades = [];
                this.subjects = data;

                const classesWithPoints = ["FOS", "BOS", "BG", "BM"];
                if (classesWithPoints.some(item => this.selectedClass.full_name.includes(item))) {
                    this.selectedGradeType = "Punkte";
                } else {
                    this.selectedGradeType = "Note";
                }
                this.headers = [
                    {
                        text: "",
                        value: "IDSchueler",
                        width: 100
                    },
                    {
                        text: "Schüler",
                        value: "name",
                        sortable: false
                    },
                    {
                        text: "Note",
                        value: "grade",
                        sortable: false
                    },
                ];
                if (this.selectedClass.full_name.includes("IBA")) {
                    this.headers.pop();
                    this.headers.push(
                        {
                            text: "Note auf eBBR Niveau",
                            value: "ibaGradeEBBR",
                            sortable: false
                        },
                        {
                            text: "Note auf MSA Niveau",
                            value: "ibaGradeMSA",
                            sortable: false
                        }
                    );
                }

                this.loading = false;
            });
    }

    @Watch("selectedSubject")
    private async subjectUpdate() {
        // get objects for class
        this.loading = true;

        if (this.selectedSubject.IDFaecher !== undefined) {
            this.http.get(`grades/exam/${this.selectedClass.IDArtSchule}/${this.selectedSubject.IDFaecher}`)
                .then((data: any) => {
                    this.grades = data;
                    this.posGrades = data.posGrades;

                    this.grades.students.forEach((element: any) => {
                        if (this.selectedClass.full_name.includes("IBA")) {
                            Object.assign(element, {
                                selectedGradeEbbr: this.grades.grades[element.IDSchueler]["eBBR"],
                                selectedGradeMsa: this.grades.grades[element.IDSchueler]["MSA"],
                            });
                        } else {
                            Object.assign(element, {
                                selectedGrade: this.grades.grades[element.IDSchueler],
                            });
                        }
                    });

                    this.loading = false;
                });
        } else {
            this.grades = [];
            this.loading = false;
        }
    }

    public getAvatar(schueler: any): string {
        return this.grades.avatars[schueler.IDSchueler.toString()];
    }

    public getFullName(schueler: any): string {
        return `${schueler.Vorname} ${schueler.Nachname}`;
    }

    public async updateGrade(item: any, ibaType: string = "") {
        let payload = {
            FachID: this.selectedSubject.IDFaecher,
            grade: item.selectedGrade?.IDNotenZensur,
            IDLehrer: this.auth.user().id,
            iba_type: ibaType
        };

        if (ibaType === "eBBR") {
            payload.grade = item.selectedGradeEbbr.IDNotenZensur;
        } else if (ibaType === "MSA") {
            payload.grade = item.selectedGradeMsa.IDNotenZensur;
        }

        this.loading = true;
        this.http.post(`grades/exam/${item.IDSchueler}`, payload).then(() => {
            this.loading = false;
        });
    }

    public async sendPDF() {
        this.loading = true;

        const payload = {
            FachID: this.selectedSubject.IDFaecher,
            class: this.selectedClass.IDArtSchule,
            IDLehrer: this.auth.user().id
        };

        this.http.post("grades/exam/send", payload).then((data: any) => {
            this.loading = false;

            this.$swal.fire({
                title: "Die Notenabgabe war erfolgreich. Vielen Dank!",
                allowOutsideClick: false,
                confirmButtonText: "Okay",
            }).then(() => {
                this.$router.go(0);
            });
        }).catch(() => {
            this.$swal.fire({
                title: "Fehler!",
                text: "Notenübersicht konnte nicht versendet werden.",
                icon: "error",
            });

            this.loading = false;
        });
    }

    private clearSelected() {
        this.grades = [];
        this.selectedClass = [];
        this.selectedSubject = [];
    }

    private async mounted() {
        // get data for classes and subjects
        this.loading = true;
        this.http.get(`grades/classesExam/${this.auth.user().id}`).then((data: any) => {
            if (data) {
                this.classes = data.data;
            }

            this.loading = false;
        });
    }
}
</script>

<style scoped>

</style>
