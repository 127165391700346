
<v-container fluid>
    <v-card style="width: 100%">
        <v-card-text>
            <v-item-group
                class="flex row ml-6 mt-1"
                flex
                style="align-content: space-between"
            >
                <v-autocomplete
                    v-model="selectedClass"
                    :items="classes"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-text="full_name"
                    item-value="IDFaecher"
                    label="Klasse"
                    no-data-text="Loading..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 260px"
                />
                <v-autocomplete
                    v-model="selectedSubject"
                    :disabled="!subjects.length"
                    :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
                    :items="subjects"
                    :return-object="true"
                    class="mr-2"
                    clearable
                    item-value="IDFaecher"
                    label="Fächer"
                    no-data-text="Loading..."
                    prepend-inner-icon="mdi-magnify"
                    style="max-width: 260px"
                />
            </v-item-group>
        </v-card-text>
    </v-card>
    <br/>
    <v-data-table
        v-if="contents"
        :headers="headers"
        :hide-default-footer="true"
        :items="contents"
        :loading="loading"
        class="elevation-1"
        disable-pagination
    >
        <template v-slot:[`item.IDSchueler`]="{ item }">
            <v-avatar class="ml-3">
                <img :src="item.avatar"/>
            </v-avatar>
        </template>

        <template v-slot:[`item.name`]="{ item }">
            <strong>{{ getFullName(item) }}</strong><br>
            <small v-show="item.saved" class="text-green" style="color:green">gespeichert</small>
            <small v-show="item.error" class="text-red" style="color:red">Fehler beim Speichern</small>
        </template>

        <template v-slot:[`item.comments`]="{ item }">
            <v-textarea v-model="item.comment" label="Hinweise..." rows="4" @change="save(item)">
            </v-textarea>
        </template>

        <template v-slot:[`item.betreuung`]="{ item }">
            <v-text-field
                v-model="item.dateFrom"
                :min="minDate"
                label="Von"
                prepend-icon="mdi-calendar"
                type="date"
                @change="save(item)"
            ></v-text-field>

            <v-text-field
                v-model="item.dateTo"
                :min="minDate"
                label="Bis"
                prepend-icon="mdi-calendar"
                type="date"
                @change="save(item)"
            ></v-text-field>
        </template>

    </v-data-table>
</v-container>
