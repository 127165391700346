<template>
    <v-container fluid>
        <v-card style="width: 100%">
            <v-card-text>
                <v-item-group
                    class="flex row ml-6 mt-1"
                    flex
                    style="align-content: space-between"
                >
                    <v-autocomplete
                        v-model="selectedClass"
                        :items="classes"
                        :return-object="true"
                        class="mr-2"
                        clearable
                        item-text="full_name"
                        item-value="IDArtSchule"
                        label="Klasse"
                        no-data-text="Laden..."
                        prepend-inner-icon="mdi-magnify"
                        style="max-width: 160px"
                    />
                    <v-autocomplete
                        v-if="subjects.length > 0"
                        v-model="selectedSubject"
                        :items="subjects"
                        :return-object="true"
                        class="mr-2"
                        clearable
                        item-text="FachName"
                        item-value="IDFaecher"
                        label="Fach"
                        no-data-text="Laden..."
                        prepend-inner-icon="mdi-magnify"
                        style="max-width: 160px"
                    />
                </v-item-group>
            </v-card-text>
        </v-card>
        <br/>
        <v-card v-if="grades">
            <v-data-table
                :headers="headers"
                :items="grades.students"
                :loading="loading"
                loading-text="Laden..."
                no-data-text="Keine Daten gefunden"
                class="elevation-1"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:[`item.IDSchueler`]="{ item }">
                    <v-avatar class="ml-3">
                        <v-img
                            :src="getAvatar(item)"
                            lazy-src="/images/no-avatar.jpg"
                            max-height="48"
                            max-width="48"
                        ></v-img>
                    </v-avatar>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <strong>{{ getFullName(item) }}</strong>
                    <br/>
                    <small
                    ><strong>{{
                            $moment().diff(item.GebDatum, "years", false) >= 18
                                ? "Volljährig"
                                : "Minderjährig"
                        }}</strong></small
                    >
                </template>
                <template v-slot:[`item.leistung`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.Leistung"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'Leistung')"
                    ></v-select>
                </template>
                <template v-slot:[`item.zuver`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.zuverlaessigkeit"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'zuverlaessigkeit')"
                    ></v-select>
                </template>
                <template v-slot:[`item.selbst`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.selbststaendigkeit"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'selbststaendigkeit')"
                    ></v-select>
                </template>
                <template v-slot:[`item.verant`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.verantwortung"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'verantwortung')"
                    ></v-select>
                </template>
                <template v-slot:[`item.team`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.team"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'team')"
                    ></v-select>
                </template>
                <template v-slot:[`item.respeckt`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.respeckt"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'respeckt')"
                    ></v-select>
                </template>
                <template v-slot:[`item.arbeit`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.arbeitsmaterial"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'arbeitsmaterial')"
                    ></v-select>
                </template>
                <template v-slot:[`item.digi`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.digi"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Bewertung"
                        single-line
                        @change="updateGrade(item, 'digi')"
                    ></v-select>
                </template>
                <template v-slot:[`item.work_grade_id`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.work_grade_id"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Note"
                        single-line
                        @change="updateGrade(item, 'work_grade_id')"
                    ></v-select>
                    <p class="meta">{{ item.selectedGrade.work_grade_meta }}</p>
                </template>
                <template v-slot:[`item.social_grade_id`]="{ item }">
                    <v-select
                        v-model="item.selectedGrade.social_grade_id"
                        :item-text="selectedGradeType"
                        :items="posGrades"
                        :return-object="true"
                        item-value="IDNotenZensur"
                        label="Note"
                        single-line
                        @change="updateGrade(item, 'social_grade_id')"
                    ></v-select>
                    <p class="meta">{{ item.selectedGrade.social_grade_meta }}</p>
                </template>
            </v-data-table>
        </v-card>
        <br>
        <v-card v-if="grades">
            <v-btn
                class="ma-3"
                color="primary"
                @click="saveNothing"
            >Bewertung abgeben
            </v-btn>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";

@Component
export default class GradeWorkSocial extends Vue {
    private classes: Array<any> = [];
    private subjects: Array<any> = [];
    private loading = false;
    private grades: any = null;
    private posGrades: Array<any> = [];
    private selectedClass: Record<string, any> = {};
    private selectedSubject: Record<string, any> = {};
    private selectedGradeType: string = "Note";
    private isFFM: boolean = false;

    private headers: Array<Record<string, any>> = [
        {
            text: "",
            value: "IDSchueler",
            width: 100,
            sortable: false
        },
        {
            text: "Schüler",
            value: "name",
            sortable: false
        },
    ];

    private http = useHttp();
    private auth = useAuth();

    public getAvatar(schueler: any): string {
        return this.grades.avatars[schueler.IDSchueler.toString()];
    }

    public getFullName(schueler: any): string {
        return `${schueler.Vorname} ${schueler.Nachname}`;
    }

    public async updateGrade(item: any, type: string) {
        const payload = {
            type: type,
            grade: item.selectedGrade[type].IDNotenZensur,
            IDLehrer: this.auth.user().id,
            subject_id: null,
        };

        if (this.isFFM) {
            payload["subject_id"] = this.selectedSubject.IDFaecher;
        }

        this.loading = true;
        this.http.post(`grades/social/${item.IDSchueler}`, payload).then(() => {
            this.loading = false;
        });
    }

    public saveNothing() {
        this.loading = true;
        const infoText = "Die Abgabe war erfolgreich. Vielen Dank!";

        if (!this.isFFM) {
            this.$swal.fire({
                title: infoText,
                allowOutsideClick: false,
                confirmButtonText: "Okay",
            }).then(() => {
                this.grades = null;
                this.selectedClass = {};
            });

            this.loading = false;

            return;
        }

        const payload = {
            subject_id: this.selectedSubject.IDFaecher,
            class_id: this.selectedClass.IDArtSchule,
            teacher_id: this.auth.user().id
        };

        this.http.post('/grades/social/send', payload)
            .then(() => {
                this.loading = false

                this.$swal.fire({
                    title: infoText,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                }).then(() => {
                    this.grades = null;
                    this.selectedClass = {};
                    this.selectedSubject = {};
                });

                this.loading = false;
            })
            .catch(() => {
                this.$swal.fire({
                    title: "Fehler!",
                    text: "Notenübersicht konnte nicht versendet werden.",
                    icon: "error",
                });

                this.loading = false;
            })
    }

    @Watch("selectedClass")
    private async classUpdater() {
        if (this.isFFM && this.selectedClass) {
            this.subjects = this.selectedClass.subjects.filter((subject: any) => {
                return subject.for_social_grades
            });

            if (this.selectedSubject
                && this.subjects.some((subject: any) => subject.IDFaecher === this.selectedSubject.IDFaecher)) {
                this.loading = true;
                this.grades = [];
                await this.loadGrades(this.selectedSubject.IDFaecher);
            } else {
                this.selectedSubject = {};
            }

            return;
        }

        if (this.isFFM && !this.selectedClass) {
            this.subjects = [];
            this.grades = null;
            return;
        }

        this.loading = true;
        if (this.selectedClass && this.selectedClass.IDArtSchule !== undefined) {
            this.grades = [];
            await this.loadGrades();
        } else {
            this.grades = null;
            this.loading = false;
        }

    }

    @Watch("selectedSubject")
    private async subjectUpdater() {
        this.loading = true;
        if (this.selectedSubject && this.selectedSubject.IDFaecher !== undefined) {
            this.grades = [];
            await this.loadGrades(this.selectedSubject.IDFaecher);
        } else {
            this.grades = null;
            this.loading = false;
        }
    }

    private async loadGrades(subject_id = null) {
        let url = `grades/social/${this.selectedClass.IDArtSchule}/${this.auth.user().id}`;
        if (subject_id) {
            url += `?subject_id=${subject_id}`;
        }

        this.http.get(url).then((data: any) => {
            this.grades = data;
            this.posGrades = data.posGrades;
            if (this.isFFM) {
                this.posGrades = this.posGrades.filter((g: any) => g.Note === g.NoteoT);
            }

            this.grades.students.forEach((element: any) => {
                Object.assign(element, {
                    selectedGrade: this.grades.grades[element.IDSchueler],
                });
            });

            this.loading = false;
        });
    }

    private async mounted() {
        // get data for classes and subjects
        this.loading = true;

        await this.http.get("params?param=appLocation").then((data: any) => {
            this.isFFM = data.data === "FFM";
        });

        if (this.isFFM) {
            this.headers.push(
                {
                    text: "Arbeitsverhalten",
                    value: "work_grade_id",
                    sortable: false
                },
                {
                    text: "Sozialverhalten",
                    value: "social_grade_id",
                    sortable: false
                },
            );
        } else {
            this.headers.push(
                {
                    text: "Lernbereitschaft",
                    value: "leistung",
                    sortable: false
                },
                {
                    text: "Zuverlässigkeit",
                    value: "zuver",
                    sortable: false
                },
                {
                    text: "Selbstständigkeit",
                    value: "selbst",
                    sortable: false
                },
                {
                    text: "Verantwortung",
                    value: "verant",
                    sortable: false
                },
                {
                    text: "Teamfähigkeit",
                    value: "team",
                    sortable: false
                },
                {
                    text: "Respekt. Umg.",
                    value: "respeckt",
                    sortable: false
                },
                {
                    text: "Arbeitsmaterialien",
                    value: "arbeit",
                    sortable: false
                },
                {
                    text: "Digi. Kompetenzen",
                    value: "digi",
                    sortable: false
                },
            );
        }
        this.http.get(`grades/classesSocial/${this.auth.user().id}`).then((data: any) => {
            if (data) {
                this.classes = data.data;
            }

            this.loading = false;
        });
    }
}
</script>

<style scoped>
.meta {
    font-size: .75rem !important;
    color: #686868 !important;
}
</style>
