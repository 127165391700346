<template>
  <v-container fluid>
    <v-card style="width: 100%">
      <v-card-text>
        <v-item-group
            flex
            class="flex row ml-6 mt-1"
            style="align-content: space-between"
        >
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 160px"
              :items="classes"
              item-text="full_name"
              item-value="IDFaecher"
              :return-object="true"
              label="Klasse"
              v-model="selectedClass"
              class="mr-2"
              no-data-text="Loading..."
              clearable
          />
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 160px"
              :items="subjects"
              item-text="FachName"
              item-value="IDFaecher"
              label="Fächer"
              :return-object="true"
              v-model="selectedSubject"
              class="mr-2"
              no-data-text="Loading..."
              :disabled="!subjects.length"
              clearable
          />
        </v-item-group>
      </v-card-text>
    </v-card>
    <br/>
    <v-data-table
        :headers="headers"
        :items="contents"
        :items-per-page="15"
        class="elevation-1"
        :loading="loading"
        disable-pagination
        :hide-default-footer="true"
    >
      <template v-slot:[`item.Datum`]="{ item }">
        {{ item.Datum | moment("DD.MM.YYYY") }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator'
import {useHttp} from '@/core/http'

@Component
export default class Contents extends Vue {
  private classes: Array<any> = []
  private subjects: Array<any> = []
  private selectedClass: Record<string, any> = {}
  private selectedSubject: Record<string, any> = {}
  private contents: Array<any> = []
  private loading = false
  private http = useHttp()
  // table releated headers
  private headers: Array<Record<string, any>> = [
    {text: 'Datum', value: 'Datum'},
    {text: 'Stunde', value: 'IDBlock'},
    {text: 'gegebenes Fach', value: 'subject.FachName'},
    {text: 'Lehrer', value: 'employee.Nachname'},
    {text: 'Inhalt', value: 'Inhalt'},
  ]

  @Watch('selectedClass')
  private async classUpdater() {
    // get objects for class
    this.selectedSubject = {}
    this.loading = true
    this.http.get(`classes/${this.selectedClass.IDArtSchule}`).then((data: any) => {
      this.subjects = data.data.subjects
      this.loading = false
    })
  }

  @Watch('selectedSubject')
  private async subjectUpdate() {
    // get objects for class
    if (Object.entries(this.selectedSubject).length) {
      this.loading = true
      this.http.get(`stundeninhalte/?IDFach=${this.selectedSubject.IDFaecher}&IDKLasse=${this.selectedClass.IDArtSchule}&limit=500&relations=subject,employee`).then((data: any) => {
        this.contents = data.data
        this.loading = false
      })
    }
  }

  private async mounted() {
    // get data for classes and subjects
    this.loading = true
    this.http.get('classes').then((data: any) => {
      if (data) {
        this.classes = data.data
      }
      this.loading = false
    })
  }
}
</script>
