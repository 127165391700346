
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between"
      >
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="classes"
            item-text="full_name"
            item-value="IDFaecher"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="mr-2"
            no-data-text="Laden..."
        />
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="subjects"
            :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
            item-value="IDFaecher"
            label="Fächer"
            :return-object="true"
            v-model="selectedSubject"
            class="mr-2"
            no-data-text="Laden..."
            :disabled="!subjects.length"
        />
      </v-item-group>
    </v-card-text>
  </v-card>
  <br/>
  <v-card v-if="grades">
    <v-data-table
        :headers="headers"
        :items="grades.students"
        disable-pagination
        hide-default-footer
        class="elevation-1"
        :loading="loading"
    >
      <template v-slot:[`item.IDSchueler`]="{ item }">
        <v-avatar class="ml-3">
          <v-img
              lazy-src="/images/no-avatar.jpg"
              :src="getAvatar(item)"
              max-width="48"
              max-height="48"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ getFullName(item) }}</strong>
        <br/>
        <small>
          <strong>{{
              $moment().diff(item.GebDatum, "years", false) >= 18
                  ? "Volljährig"
                  : "Minderjährig"
            }}</strong>
          <strong v-if="selectedClass.full_name.includes('IBA')" class="ml-2"
                  :class="item.iba_niveau.includes('GR') ? 'error--text' : ''">({{ item.iba_niveau }})</strong>
        </small>
      </template>
      <template v-slot:[`item.grade`]="{ item }">
        <v-select
            :items="posGrades"
            label="Noten"
            :item-text="selectedGradeType"
            item-value="IDNotenZensur"
            single-line
            :return-object="true"
            v-model="item.selectedGrade"
            @change="updateGrade(item)"
            :disabled="isDisabled"
        ></v-select>
      </template>
    </v-data-table>
  </v-card>
  <br>
  <v-card class="d-flex align-center pa-3" v-if="grades">
    <v-btn
        color="primary"
        @click="sendPDF"
        :disabled="studentsWithNoGrade !== 0">
      Notenübersicht versenden
    </v-btn>
    <span class="warning--text pl-3" v-show="studentsWithNoGrade > 0">
      Die Notenübersicht kann nicht versendet werden, weil für mindestens einen Schüler keine Note vergeben wurde.
    </span>
  </v-card>
</v-container>
