<template>
  <v-container fluid>
    <v-card style="width: 100%" v-if="!dashboard">
      <v-card-text>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
                :loading="loading"
            >
              <v-icon small> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="next"
                :loading="loading"
            >
              <v-icon small> mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined :loading="exportLoading" @click="exportIcal">
              Export iCal
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-item-group
            flex
            class="flex row ml-6 pt-2 pb-2"
            style="align-content: space-between"
        >
          <!-- Stundenplan für &nbsp;
          <strong>KW {{ new Date() | moment("WW") }}</strong>-->
        </v-item-group>
      </v-card-text>
    </v-card>
    <v-card>
      <v-sheet class="mt-2" height="100%">
        <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            :first-interval="7"
            :interval-count="12"
            :min-weeks="1"
            locale="DE"
            @click:event="showEvent"
            @change="shouldGetEvents"
        >
          <template v-slot:day-body="{ date, week }">
            <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import _ from 'lodash'
import {useHttp} from '@/core/http'
import {useAuth} from '@/plugins/auth'

@Component
export default class Timetable extends Vue {
  @Prop({required: false, default: 'week'}) type: string | undefined
  @Prop({required: false, default: false}) dashboard: boolean | undefined
  @Prop({required: false}) date: string | undefined
  private types = ['month', 'week', 'day', '4day']
  private mode = 'stack'
  private modes = ['stack', 'column']
  private loading = false
  private exportLoading = false
  private data = []
  private dataByDay = {}
  private calender: any = null
  private weekday = [1, 2, 3, 4, 5]
  private nowY: string = '0px'
  private weekdays = [
    {text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6]},
    {text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0]},
    {text: 'Mon - Fri', value: [1, 2, 3, 4, 5]},
    {text: 'Mon, Wed, Fri', value: [1, 3, 5]},
  ]
  private focus = ''
  private value = ''
  private events: Array<any> = []
  private colors = [
    'blue',
    'indigo',
    'deep-purple',
    'cyan',
    'green',
    'orange',
    'grey darken-1',
  ]
  private names = [
    'Meeting',
    'Holiday',
    'PTO',
    'Travel',
    'Event',
    'Birthday',
    'Conference',
    'Party',
  ]
  private http = useHttp()
  private auth = useAuth()

  public typeToLabel = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    '4day': '4 Days',
  }

  public async shouldGetEvents(): Promise<void> {
    if (this.calender) {
      this.loading = true
      this.http.get(`/teachers/${this.auth.user().id}/schedules/${this.calender.lastStart.date}/${this.calender.lastEnd.date}`)
          .then((data: any) => {
            this.data = data.data
            this.events = this.buildEvents(data.data)
            this.loading = false
          })
    }
  }

  public getEventColor(event: Record<string, any>): string {
    return event.color
  }

  public rnd(a: number, b: number): number {
    return Math.floor((b - a + 1) * Math.random()) + a
  }

  public viewDay(date: any): void {
    this.focus = date
    this.type = 'day'
  }

  public showEvent(e: any): void {
    const {nativeEvent, event} = e

    const selectedEvent = event
    const selectedElement = nativeEvent.target

    if (this.dashboard) {
      this.$router.push({
        name: 'absents.class', params: {
          classId: selectedEvent.IDKlasse,
          block: selectedEvent.Stunden,
          subjectId: selectedEvent.IDFach,
        }
      })
    }

    nativeEvent.stopPropagation()
  }

  public setToday() {
    this.focus = ''
  }

  public async prev() {
    this.calender.prev()
  }

  public next() {
    this.calender.next()
  }

  /**
   * build events with merging of data
   */
  public buildEvents(data: any): any {
    let rawEvents: Array<unknown> = []
    const events: Array<unknown> = []
    if (!data.length) return []
    try {
      let merge: Array<any> = []
      let blocks: any = null
      // get list of unique objects per day
      const arrDay: any = {}

      data.forEach((element: any) => {
        const day = this.$moment(<string>element.Start).format('DD');
        arrDay[day] = []
      })

      data.forEach((element: any) => {
        arrDay[this.$moment(<string>element.Start).format('DD')].push(element)
      })

      // maybe get rid of this. its for prevention of observeable conversion
      const clone = JSON.stringify(arrDay)
      this.dataByDay = JSON.parse(clone)
      const loopData = JSON.parse(clone);

      // merge blocks
      for (const day in loopData) {
        const subset = _.cloneDeep(loopData[day])
        blocks = _.groupBy(subset, 'Stunden')
        for (const entry in blocks) {

          // todo check for merge of classes

          const holder = {
            name:
                _.uniq(
                    blocks[entry].map((item: any) => {
                      return item.Klasse
                    })
                ).join(' ')
                + ' / ' + blocks[entry][0]['Fach']
                + ' / Raum: ' + blocks[entry][0]['Raum'],
            block: blocks[entry][0]['Block'],
            start: new Date(blocks[entry][0]['Start'].replace(/-/g, "/")),
            end: new Date(blocks[entry][blocks[entry].length - 1]['End'].replace(/-/g, "/")),
            color: blocks[entry][0]['Color'].toLowerCase() || 'teal',
            IDKlasse: _.uniq(
                blocks[entry].map((item: any) => {
                  return item.IDKlasse
                })
            ).join('-'),
            Stunden: _.uniq(
                blocks[entry].map((item: any) => {
                  return item.Stunden
                })
            ).join('-'),
            IDFach: blocks[entry][0]['IDFach'],
            timed: true,
          }


          rawEvents.push(holder)
        }

        // check for doubles in rawEvent
        let prevItem: any;
        rawEvents.forEach((item: any, index: number) => {

          if (!prevItem) {
            // check if it is the second part of a block, if so direct push
            if (item.Stunden / 2 == item.block || item.block != rawEvents[index + 1]?.block) {
              events.push(item);
              prevItem = undefined;
            } else {
              prevItem = item;
            }
          } else {
            if (prevItem.block === item.block && prevItem.name === item.name) {
              item.start = prevItem.start;
              item.Stunden = prevItem.Stunden + '-' + item.Stunden;
            } else {
              events.push(prevItem);
            }
            events.push(item);
            prevItem = undefined;
          }

        })

        // is there still a prevItem -> push it
        if (prevItem) {
          events.push(prevItem);
        }
        // reset rawItems
        rawEvents = []
      }


      return events
    } catch (error) {
      console.log(error)
    }
  }

  private exportIcal() {
    this.http.get(`teachers/${this.auth.user().id}/export-ical`, {responseType: 'blob'})
        .then((response: any) => {
          const blob = new Blob([response], {type: 'text/calendar; charset=utf-8'})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `teacher-${this.auth.user().id}-schedule-ical.ics`)
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
  }

  private async mounted() {
    const currentDate = this.date ? this.$moment(<string>this.date) : this.$moment()

    let weekStart =
        this.type !== 'day'
            ? currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
            : currentDate.format('YYYY-MM-DD')
    let weekEnd =
        this.type !== 'day'
            ? currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
            : currentDate.format('YYYY-MM-DD')

    this.http.get(`teachers/${this.auth.user().id}/schedules/${weekStart}/${weekEnd}`)
        .then((data: any) => {
          this.data = data.data

          this.events = this.buildEvents(data.data)
          this.calender = this.$refs['calendar']
          this.loading = false
        })
  }
}
</script>

<style type="scss" scoped>
/*
todo this if for that fancy time horizontal line if needed
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
*/
</style>
