
<v-container fluid>
  <v-card style="width: 100%">
    <v-card-text>
      <v-item-group
          flex
          class="flex row ml-6 mt-1"
          style="align-content: space-between"
      >
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="subjects"
            :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
            item-value="IDFaecher"
            :return-object="true"
            label="Fächer"
            v-model="selectedSubject"
            class="mr-2"
            no-data-text="Loading..."
            clearable
        />

        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 160px"
            :items="students"
            :item-text="(item) => `${item.Vorname} ${item.Nachname} (${item.class})`"
            item-value="id"
            :return-object="true"
            v-if="selectedSubject"
            label="Schüler hinzufügen"
            class="mr-2"
            no-data-text="Loading..."
            v-model="selectedStudent"
            clearable
        />
      </v-item-group>
    </v-card-text>
  </v-card>
  <br/>
  <v-card v-if="lessons">
    <v-data-table
        :headers="headers"
        :items="lessons"
        disable-pagination
        :hide-default-footer="true"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
    >
      <template v-slot:[`item.Datum`]="{ item }">
        {{ item.Datum | moment("DD.MM.YYYY") }}
      </template>

      <template v-slot:[`item.IDSchueler`]="{ item }">
        <v-avatar class="ml-3">
          <img :src="item.avatar"/>
        </v-avatar>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <strong>{{ getFullName(item) }}</strong>
        <br/>
        <small
        ><strong>Klasse {{ item.class }}</strong></small
        >
        <br/>
        <small v-if="item.from"
        >Verpflichtet: {{ item.from }} - {{ item.to }}</small
        >
        <small v-else> Freiwillig </small>
        <div style="color: green" v-if="item.saved"><br>gespeichert</div>
      </template>
      <template v-slot:[`item.comments`]="{ item }">
        <v-textarea
            disabled=disabled
            v-model="item.comment"
        ></v-textarea>
      </template>
      <template v-slot:[`item.existence`]="{ item }">
        <v-radio-group
            v-model="item.existence"
            @change="updateExistence(item)"
        >
          <!-- button G -->
          <!-- sonderfall E -->

          <v-radio label="Anwesend" value="A"></v-radio>
          <v-radio label="Fehlend" value="F"></v-radio>
          <v-select
              :items="getTimeItems()"
              label="Anwesenheit in Minuten"
              @change="updateExistence(item)"
              v-model="item.existenceTime"
              v-if="item.existence === 'A'"
          ></v-select>
        </v-radio-group>
      </template>
    </v-data-table>
  </v-card>
</v-container>
