
<div>
  <v-card :loading="loading">
    <v-card-title>Statistik</v-card-title>
    <v-card-text class="flex row" style="width: 80%; margin-left: 1px">
      <ClassStudentSubjectSelect
          :class-data="classes"
          :show-student-select="false"
          :only-assessable-subjects="false"
          @updateClass="this.updateClass"
          @updateSubject="this.updateSubject"
          @updateStudents="this.updateStudents"
          @toggleLoading="this.toggleLoading"/>
      <v-item-group class="flex row ml-6 mt-1">
        <v-text-field
            label="Von"
            class="input-date"
            type="date"
            v-model="startDate"/>
        <v-text-field
            label="Bis"
            class="input-date"
            type="date"
            v-model="endDate"/>
        <v-btn
            class="d-flex align-self-center ml-5 accent"
            :disabled="!isValid || loading"
            @click="load()">
          Laden
        </v-btn>
      </v-item-group>
    </v-card-text>
  </v-card>
  <br>
  <v-card v-if="showTable">
    <v-card-text>
      <v-simple-table fixed-header height="70vh">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left t-header">Schülername</th>
            <th class="text-right t-header">Gesamtstunden</th>
            <th class="text-right t-header">Anwesend</th>
            <th class="text-right t-header">Entschuldigt</th>
            <th class="text-right t-header">Unentschuldigt</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="student in students" :key="student.IDSchueler">
            <td>{{ student.Nachname }}, {{ student.Vorname }}</td>
            <td class="text-right">{{ absentData[student.IDSchueler].ges }}</td>
            <td class="text-right">
              {{ absentData[student.IDSchueler].Anwesend }}
              <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentA }} %)</span>
            </td>
            <td class="text-right">
              {{ absentData[student.IDSchueler].FsE }}
              <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentE }} %)</span>
            </td>
            <td class="text-right">
              {{ absentData[student.IDSchueler].FsUE }}
              <span style="font-size: 12px">({{ absentData[student.IDSchueler].prozentU }} %)</span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</div>
