
<v-app>
  <notifications/>
  <v-navigation-drawer
      v-model="drawer"
      app>
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar size="60" style="margin-left: auto; margin-right: auto">
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $auth.user().name }}
            {{ $auth.user().lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ $auth.user().email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/grades" v-if="grades">
        <v-list-item-icon>
          <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Notentool</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/gradesWorkSocial" v-if="arbSoz">
        <v-list-item-icon>
          <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Arbeit.- & Soz.-verhalten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/gradesExam" v-if="exam">
        <v-list-item-icon>
          <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Prüfungsnoten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/subGrades" v-if="quarterGrades">
        <v-list-item-icon>
          <v-icon>mdi-certificate</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Notenrechner</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openInNewTab(cloudLink)" v-if="cloudLink">
        <v-list-item-icon>
          <v-icon>mdi-cloud</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Rackow Cloud (alt)</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openInNewTab('https://outlook.office.com/mail')">
        <v-list-item-icon>
          <v-icon>mdi-email-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Outlook Web</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openInNewTab('https://teams.microsoft.com')">
        <v-list-item-icon>
          <v-icon>mdi-microsoft-teams</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>MS Teams</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openInNewTab('https://portal.office.com/onedrive')">
        <v-list-item-icon>
          <v-icon>mdi-microsoft-onedrive</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>MS OneDrive</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="openSupportDialog">
        <v-list-item-icon>
          <v-icon>mdi-face-agent</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Support
          </v-list-item-title>
        </v-list-item-content>

      </v-list-item>

      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Support Anfrage</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p class="text-body-1">
                Dieses Formular dient dazu, Tickets für die IT Abteilung der Schule zu erstellen. Hierbei können
                sämtliche Probleme in den Räumen geschildert werden. Dazu zählen Probleme mit der
                Technik (Software & Hardware) sowie Probleme im Klassenraum selbst, z.B. Klimaanlage oder Rollos.
              </p>
              <v-form>
                <v-row>
                  <v-col cols="6">
                    <v-select
                        label="Raum"
                        v-model="form.room_id"
                        :items="rooms"
                        item-text="Name"
                        item-value="IDRaum"
                        no-data-text="Daten werden geladen..."
                        :error-messages="roomErrors"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        label="Kategorie"
                        v-model="form.category_id"
                        :items="ticketCategories"
                        item-text="name"
                        item-value="id"
                        no-data-text="Daten werden geladen..."
                        :error-messages="categoryErrors"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="text-body-1">
                      Bitte beschreiben Sie in der unteren Textbox das Problem möglichst genau, sodass sich unsere IT
                      Abteilung schnellstens drum kümmern kann.
                    </p>
                    <v-textarea
                        name="content"
                        filled
                        label="Beschreibung"
                        auto-grow
                        rows="10"
                        v-model="form.content"
                        :error-messages="contentErrors"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="accent"
                @click="dialog = false">
              Abbrechen
            </v-btn>
            <v-btn
                color="primary"
                :disabled="disableTicketBtn"
                @click="createTicket">
              Absenden
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar app style="z-index: 999">
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>RACKOW DKB</v-toolbar-title>
  </v-app-bar>

  <v-main class="flex fill-height" style="background-color: lightgrey">
    <!-- Provides the application the proper gutter -->

    <!-- If using vue-router -->
    <!--<keep-alive :include="cachingComponents">-->
    <router-view></router-view>
    <!-- </keep-alive> -->
  </v-main>
</v-app>
