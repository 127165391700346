
<v-item-group class="flex row ml-6 mt-1">
    <v-autocomplete
        v-model="selectedClass"
        :items="classes"
        :return-object="true"
        class="mr-2"
        clearable
        item-text="full_name"
        item-value="IDFaecher"
        label="Klasse"
        no-data-text="Laden..."
        prepend-inner-icon="mdi-magnify"
        style="max-width: 160px"
        @change="selectClass"/>
    <v-autocomplete
        v-show="showStudentSelect"
        v-model="selectedStudent"
        :disabled="!students.length"
        :item-text="item => `${item.Nachname}, ${item.Vorname}`"
        :items="students"
        :return-object="true"
        class="mr-2"
        clearable
        item-value="IDSchueler"
        label="Schüler"
        no-data-text="Laden..."
        prepend-inner-icon="mdi-magnify"
        style="max-width: 320px"
        @change="selectStudent"/>
    <v-autocomplete
        v-model="selectedSubject"
        :disabled="!subjects.length"
        :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
        :items="subjects"
        :return-object="true"
        class="mr-2"
        clearable
        item-value="IDFaecher"
        label="Fach"
        no-data-text="Laden..."
        prepend-inner-icon="mdi-magnify"
        style="max-width: 320px"
        @change="selectSubject"/>
</v-item-group>
