<template>
  <v-container fluid>
    <v-card style="width: 100%">
      <v-card-text>
        <v-item-group
            flex
            class="flex row ml-6 mt-1"
            style="align-content: space-between"
        >
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 200px"
              :items="classes"
              item-text="full_name"
              item-value="IDFaecher"
              :return-object="true"
              label="Klasse"
              v-model="selectedClass"
              class="mr-2"
              no-data-text="Loading..."
              clearable
          />
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 200px"
              :items="subjects"
              :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
              item-value="IDFaecher"
              label="Fächer"
              :return-object="true"
              v-model="selectedSubject"
              class="mr-2"
              no-data-text="Loading..."
              :disabled="!subjects.length"
              clearable
          />
          <v-btn-toggle
              borderless
              color="red"
              class="ml-6"
              v-model="selectedType"
          >
            <v-btn value="s">Stunden</v-btn>
            <v-btn value="b">Blöcke</v-btn>
          </v-btn-toggle>
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 200px"
              :items="filtertBlocks"
              item-text="text"
              item-value="value"
              :label="dropLable"
              :return-object="true"
              v-model="selectedBlock"
              class="ml-6 mr-2"
              no-data-text="Loading..."
              :disabled="!blocks.length"
              clearable
          />
          <v-btn
              x-large
              @click="openAbsentClass"
              class="ml-6"
          >Klassenbuch öffnen
          </v-btn>
        </v-item-group>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {useHttp} from '@/core/http'
import swal from "sweetalert";

@Component
export default class AbsentsChange extends Vue {
  private classes: Array<any> = []
  private subjects: Array<any> = []
  private selectedClass: Record<string, any> = {}
  private selectedSubject: Record<string, any> = {}
  private selectedBlock: Record<string, any> = {}
  private selectedType: string = "b"
  private contents: Array<any> = []
  private loading = false
  private http = useHttp()
  private blocks: Array<Record<string, any>> = [
    {text: '1. Block', value: '1-2', type: 'b'},
    {text: '1. Stunde', value: '1', type: 's'},
    {text: '2. Stunde', value: '2', type: 's'},
    {text: '2. Block', value: '3-4', type: 'b'},
    {text: '3. Stunde', value: '3', type: 's'},
    {text: '4. Stunde', value: '4', type: 's'},
    {text: '3. Block', value: '5-6', type: 'b'},
    {text: '5. Stunde', value: '5', type: 's'},
    {text: '6. Stunde', value: '6', type: 's'},
    {text: '4. Block', value: '7-8', type: 'b'},
    {text: '7. Stunde', value: '7', type: 's'},
    {text: '8. Stunde', value: '8', type: 's'},
    {text: '5. Block', value: '9-10', type: 'b'},
    {text: '9. Stunde', value: '9', type: 's'},
    {text: '10. Stunde', value: '10', type: 's'},
    {text: '6. Block', value: '11-12', type: 'b'},
    {text: '11. Stunde', value: '11', type: 's'},
    {text: '12. Stunde', value: '12', type: 's'}
  ]
  private filtertBlocks: Array<Record<string, any>> = []
  private dropLable = "Welcher Block"

  @Watch('selectedClass')
  private async classUpdater() {
    // get objects for class
    this.selectedSubject = {}
    this.loading = true
    this.http.get(`classes/${this.selectedClass.IDArtSchule}`).then((data: any) => {
      this.subjects = data.data.subjects
      this.loading = false
    })
  }

  private openAbsentClass() {
    if (!this.selectedClass.IDArtSchule) {
      swal("Warnung", "Bitte eine Klasse angeben.", "warning")
      return
    }
    if (!this.selectedSubject.IDFaecher) {
      swal("Warnung", "Bitte ein Fach angeben.", "warning")
      return
    }
    if (!this.selectedBlock.value) {
      swal("Warnung", "Bitte eine Stunde oder einen Block angeben.", "warning")
      return
    }

    this.$router.push({
      name: 'absents.class', params: {
        classId: this.selectedClass.IDArtSchule,
        block: this.selectedBlock.value,
        subjectId: this.selectedSubject.IDFaecher,
      }
    })
  }

  @Watch('selectedType')
  private filterBlocks() {
    this.filtertBlocks = this.blocks.filter(block => {
      return block.type == this.selectedType
    })
    // change lable text
    if (this.selectedType == "b") {
      this.dropLable = "Welcher Block"
    } else {
      this.dropLable = "Welche Stunde"
    }
  }

  private async mounted() {
    // get data for classes and subjects
    this.loading = true
    this.http.get('classes').then((data: any) => {
      if (data) {
        this.classes = data.data
      }
      this.loading = false
    })

    //prefill list of blocks#
    this.filterBlocks()
  }


}

</script>

<style scoped>

</style>
