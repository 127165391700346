
<v-container fluid class="d-flex justify-space-between mb-6">
  <v-overlay :value="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </v-overlay>
  <v-row v-if="!loading">
    <v-col cols="6" v-for="doc in docs" v-bind:key="doc">
      <v-card
          elevation="2"
      >
        <v-card-title>{{ doc.name }}</v-card-title>
        <v-card-subtitle>{{ doc.date }}</v-card-subtitle>
        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              @click="download(doc.name)"
          >Herunterladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card
          elevation="2"
      >
        <v-card-title>Klassenlisten</v-card-title>
        <v-autocomplete
            prepend-inner-icon="mdi-magnify"
            style="max-width: 260px"
            :items="classes"
            item-text="full_name"
            item-value="IDFaecher"
            :return-object="true"
            label="Klasse"
            v-model="selectedClass"
            class="ml-4"
            no-data-text="Loading..."
            clearable
        />
        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
              @click="classList"
          >Herunterladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</v-container>
