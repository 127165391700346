<template>
  <v-container fluid>
    <v-card style="width: 100%">
      <v-card-text>
        <v-item-group
            flex
            class="flex row ml-6 mt-1"
            style="align-content: space-between"
        >
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 260px"
              :items="classes"
              item-text="full_name"
              item-value="IDFaecher"
              :return-object="true"
              label="Klasse"
              v-model="selectedClass"
              class="mr-2"
              no-data-text="Laden..."
          />
          <v-autocomplete
              prepend-inner-icon="mdi-magnify"
              style="max-width: 260px"
              :items="subjects"
              :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
              item-value="IDFaecher"
              label="Fächer"
              :return-object="true"
              v-model="selectedSubject"
              class="mr-2"
              no-data-text="Laden..."
              :disabled="!subjects.length"
          />
        </v-item-group>
      </v-card-text>
    </v-card>
    <br/>
    <v-card v-if="grades">
      <v-data-table
          :headers="headers"
          :items="grades.students"
          disable-pagination
          hide-default-footer
          class="elevation-1"
          :loading="loading"
      >
        <template v-slot:[`item.IDSchueler`]="{ item }">
          <v-avatar class="ml-3">
            <v-img
                lazy-src="/images/no-avatar.jpg"
                :src="getAvatar(item)"
                max-width="48"
                max-height="48"
            ></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <strong>{{ getFullName(item) }}</strong>
          <br/>
          <small>
            <strong>{{
                $moment().diff(item.GebDatum, "years", false) >= 18
                    ? "Volljährig"
                    : "Minderjährig"
              }}</strong>
            <strong v-if="selectedClass.full_name.includes('IBA')" class="ml-2"
                    :class="item.iba_niveau.includes('GR') ? 'error--text' : ''">({{ item.iba_niveau }})</strong>
          </small>
        </template>
        <template v-slot:[`item.grade`]="{ item }">
          <v-select
              :items="posGrades"
              label="Noten"
              :item-text="selectedGradeType"
              item-value="IDNotenZensur"
              single-line
              :return-object="true"
              v-model="item.selectedGrade"
              @change="updateGrade(item)"
              :disabled="isDisabled"
          ></v-select>
        </template>
      </v-data-table>
    </v-card>
    <br>
    <v-card class="d-flex align-center pa-3" v-if="grades">
      <v-btn
          color="primary"
          @click="sendPDF"
          :disabled="studentsWithNoGrade !== 0">
        Notenübersicht versenden
      </v-btn>
      <span class="warning--text pl-3" v-show="studentsWithNoGrade > 0">
        Die Notenübersicht kann nicht versendet werden, weil für mindestens einen Schüler keine Note vergeben wurde.
      </span>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";

@Component
export default class Grades extends Vue {
  private classes: Array<any> = []
  private subjects: Array<any> = []
  private loading = false
  private grades: any = null
  private posGrades: Array<any> = []
  private selectedClass: Record<string, any> = {}
  private selectedSubject: Record<string, any> = {}
  private selectedGradeType: string = "Punkte"
  private isDisabled: boolean = false
  private isFFM: boolean = false
  private emptyGradeID: number = 0
  private studentsWithNoGrade: number = 0

  private homeworkSubjects: Array<number> = []

  private headers: Array<Record<string, any>> = [
    {text: '', value: 'IDSchueler', width: 100},
    {text: 'Schüler', value: 'name'},
    {text: 'Note', value: 'grade', sortable: false},
  ];

  private http = useHttp()
  private auth = useAuth()

  private async mounted() {
    // get data for classes and subjects
    this.loading = true
    this.http.get(`grades/classes/${this.auth.user().id}`).then((data: any) => {
      if (data) {
        this.classes = data.data.filter((teacherClass: any) => teacherClass.opened_grade_tool === 1);
      }

      this.loading = false
    })

    this.http.get('params?param=appLocation').then((data: any) => {
      this.isFFM = data.data === 'FFM'
      this.updateDisabledStatus()
    })

    this.http.get('grades/empty-grade-id').then((data: any) => {
      this.emptyGradeID = data
    })

    this.loadHomeWorkSubjects()
  }

  @Watch('selectedClass')
  private async classUpdater() {
    // get objects for class
    this.selectedSubject = {}
    this.studentsWithNoGrade = 0
    this.loading = true

    this.http.get(`classes/${this.selectedClass.IDArtSchule}`)
        .then((data: any) => {
          this.grades = []
          this.subjects = data.data.subjects.filter(subject => subject.assessable == 1)  // filter only subjects which are assessable

          if (this.selectedClass.full_name.includes('FOS') || this.selectedClass.full_name.includes('BOS') || this.selectedClass.full_name.includes('BG')) {
            this.selectedGradeType = "Punkte"
          } else {
            this.selectedGradeType = "Note"
          }

          this.updateDisabledStatus()
          this.loading = false
        })
  }

  @Watch('selectedSubject')
  private async subjectUpdate() {
    // get objects for class
    this.loading = true

    if (this.selectedSubject.IDFaecher !== undefined) {
      this.http.get(`grades/${this.selectedClass.IDArtSchule}/${this.selectedSubject.IDFaecher}`)
          .then((data: any) => {
            this.grades = data
            this.posGrades = data.posGrades

            this.grades.students.forEach((element: any) => {
              Object.assign(element, {
                selectedGrade: this.grades.grades[element.IDSchueler],
              })
            })

            this.updateDisabledStatus()
            this.countStudentsWithoutGrade()

            this.loading = false
          })
    } else {
      this.grades = []
      this.loading = false
    }
  }

  public getAvatar(schueler: any): string {
    return this.grades.avatars[schueler.IDSchueler.toString()]
  }

  public getFullName(schueler: any): string {
    return `${schueler.Vorname} ${schueler.Nachname}`
  }

  public async updateGrade(item: any) {
    const payload = {
      FachID: this.selectedSubject.IDFaecher,
      grade: item.selectedGrade.IDNotenZensur,
      IDLehrer: this.auth.user().id
    }

    let prevGradeId = this.grades.grades[item.IDSchueler]
    let selectedGradeId = item.selectedGrade.IDNotenZensur
    if (selectedGradeId == this.emptyGradeID) {
      this.studentsWithNoGrade++
    } else if (prevGradeId == this.emptyGradeID) {
      this.studentsWithNoGrade--
    }

    this.loading = true
    this.http.post(`grades/${item.IDSchueler}`, payload).then(() => {
      this.grades.grades[item.IDSchueler] = selectedGradeId
      this.loading = false
    })
  }

  public async sendPDF() {
    this.loading = true

    const payload = {
      FachID: this.selectedSubject.IDFaecher,
      class: this.selectedClass.IDArtSchule,
      IDLehrer: this.auth.user().id
    }

    this.http.post('grades/send', payload).then((data: any) => {
      this.loading = false

      this.$swal.fire({
        title: 'Die Notenabgabe war erfolgreich. Vielen Dank!',
        allowOutsideClick: false,
        confirmButtonText: 'Okay',
      }).then(() => {
        if (this.homeworkSubjects.includes(this.selectedSubject.IDFaecher)) {
          this.$swal.fire({
            title: 'Möchten Sie Schülerinnen und Schüler dieser Klasse zur Hausaufgabenbetreuung verpflichten?',
            showDenyButton: true,
            confirmButtonText: 'Ja',
            denyButtonText: 'Nein',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push({
                path: '/homework-create',
                query: {
                  selectedClassId: this.selectedClass.IDArtSchule,
                  selectedSubjectId: this.selectedSubject.IDFaecher
                }
              })
            } else {
              this.$router.go(0)
            }
          })
        } else {
          this.$router.go(0)
        }
      })
    })
        .catch(() => {
            this.$swal.fire({
                title: "Fehler!",
                text: "Notenübersicht konnte nicht versendet werden.",
                icon: "error",
            });
        })
  }

  public async loadHomeWorkSubjects() {
    await this.http.get(`homeworks/subjects`).then((data: any) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          this.homeworkSubjects.push(data[i].IDFaecher);
        }
      }
    })
  }

  private clearSelected() {
    this.grades = []
    this.selectedClass = []
    this.selectedSubject = []
  }

  private countStudentsWithoutGrade() {
    this.studentsWithNoGrade = 0

    this.grades.students.forEach((element: any) => {
      if (this.grades.grades[element.IDSchueler] === this.emptyGradeID) {
        this.studentsWithNoGrade++
      }
    })
  }

  private updateDisabledStatus() {
    // In FFM, projects and subject with id 226 grading are allowed.
    const isProject = this.selectedSubject?.fachkuerzel?.startsWith("AG_Projekt") || false;
    this.isDisabled = this.isFFM && this.selectedSubject?.IDFaecher != 226 && !isProject;
  }
}
</script>

<style scoped>

</style>
