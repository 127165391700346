import {NavigationGuardNext, Route} from "vue-router/types/router";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";

const validSchedule = (to: Route, from: Route, next: NavigationGuardNext): any => {

    if (from.path === "/absent/change") {
        next();
        return;
    }

    const http = useHttp();
    const auth = useAuth();

    const data = {
        "teacher_id": auth.user().id,
        "class_id": to.params.classId,
        "block": to.params.block,
        "subject_id": to.params.subjectId,
    };

    http.post("/schedule/validate", data)
        .then(res => {
            if (res) {
                next();
            } else {
                next({name: "dashboard"});
            }
        })
        .catch(() => next({name: "dashboard"}));
};

export default validSchedule;
