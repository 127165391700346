export function calculateStartConference(selectedConference: number, selectedClass: any): number {

    let startConference = 0

    // FOS students have 8 conferences in total
    // 1-4 first year
    // 5-8 second year
    // if selectedConference is bigger than 4 , student is his/her second year
    if (selectedClass.full_name.includes('BG') || selectedClass.full_name.includes('FOS')) {
        if (selectedConference > 4) {
            if (selectedConference > 8) {
                startConference = 9
            } else {
                startConference = 5
            }
        } else {
            startConference = 1
        }
    } else {
        // Gym or Real selected
        let fullYears = Math.floor(selectedConference / 4)
        let remainder = selectedConference % 4

        if (remainder == 0) {
            //last conference for this year
            startConference = ((fullYears - 1) * 4) + 1
        } else {
            startConference = (fullYears * 4) + 1
        }
    }


    return startConference;
}


export function calculateBGAverages(grades: any, startConference: number, selectedClass: any, bg11MinorCourse: any, selectedGradeType: string = 'Punkte') {
    let averages: any = []
    let stop = startConference + 4

    for (let i = startConference; i <= stop; i++) {
        averages[i] = []

        let exam = 0
        let verbal = 0
        let countExams = 0
        let countVerbals = 0

        // BG classes add only Leistungsbeurteilung and Halbjahr together
        if (i % 2 == 0) {
            // is even -> Halbjahr
            for (let j = i; j >= (i - 1); j--) {
                let tmpExam = grades[j].exam[selectedGradeType] ?? './.'
                let tmpVerbal = grades[j].verbal[selectedGradeType] ?? './.'

                if (!isNaN(tmpExam)) {
                    exam += parseInt(tmpExam)
                    countExams++;
                }

                if (!isNaN(tmpVerbal)) {
                    verbal += parseInt(tmpVerbal)
                    countVerbals++;
                }
            }

        } else {
            // is odd -> LB
            let tmpExam = grades[i].exam[selectedGradeType] ?? './.'
            let tmpVerbal = grades[i].verbal[selectedGradeType] ?? './.'

            if (!isNaN(tmpExam)) {
                exam = parseInt(tmpExam)
                countExams++;
            }

            if (!isNaN(tmpVerbal)) {
                verbal = parseInt(tmpVerbal)
                countVerbals++;
            }
        }

        exam = exam / countExams
        verbal = verbal / countVerbals

        if (isNaN(exam)) {
            averages[i]['exam'] = './.';
        } else {
            averages[i]['exam'] = exam.toFixed(1);
        }

        if (isNaN(verbal)) {
            averages[i]['verbal'] = './.';
        } else {
            averages[i]['verbal'] = verbal.toFixed(1);
        }

        if (!isNaN(exam) && !isNaN(verbal)) {
            if (selectedClass.full_name.includes('BG 11') && bg11MinorCourse) {
                // BG 11 and a minor course is selected
                averages[i]['main'] = ((exam + (verbal * 2)) / 3).toFixed(1)
            } else {
                averages[i]['main'] = ((exam + verbal) / 2).toFixed(1)
            }
        }

        if (isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = 0;
        }

        if (!isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = exam.toFixed(1);
        }

        if (isNaN(exam) && !isNaN(verbal)) {
            averages[i]['main'] = verbal.toFixed(1);
        }
    }

    return averages;
}

export function calculateFOSAverages(grades: any, startConference: number, selectedGradeType: string = 'Punkte') {
    let averages: any = []
    let stop = startConference + 4

    for (let i = startConference; i <= stop; i++) {
        averages[i] = []

        let exam = 0
        let verbal = 0
        let countExams = 0
        let countVerbals = 0

        for (let j = startConference; j <= i; j++) {

            let tmpExam = grades[j].exam[selectedGradeType] ?? './.'
            let tmpVerbal = grades[j].verbal[selectedGradeType] ?? './.'

            if (!isNaN(tmpExam)) {
                exam += parseInt(tmpExam)
                countExams++;
            }

            if (!isNaN(tmpVerbal)) {
                verbal += parseInt(tmpVerbal)
                countVerbals++;
            }
        }

        exam = exam / countExams
        verbal = verbal / countVerbals

        if (isNaN(exam)) {
            averages[i]['exam'] = './.';
        } else {
            averages[i]['exam'] = exam.toFixed(1);
        }

        if (isNaN(verbal)) {
            averages[i]['verbal'] = './.';
        } else {
            averages[i]['verbal'] = verbal.toFixed(1);
        }

        if (!isNaN(exam) && !isNaN(verbal)) {
            averages[i]['main'] = ((exam + verbal) / 2).toFixed(1)
        }

        if (isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = 0;
        }

        if (!isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = exam.toFixed(1);
        }

        if (isNaN(exam) && !isNaN(verbal)) {
            averages[i]['main'] = verbal.toFixed(1);
        }
    }

    return averages;
}

export function calculateGymRealAverages(grades: any, startConference: number, minorCourse: boolean = false) {
    const number = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})
    let averages: any = []
    let stop = startConference + 4

    for (let i = startConference; i <= stop; i++) {
        averages[i] = []

        let exam = 0
        let verbal = 0
        let countExams = 0
        let countVerbals = 0

        for (let j = startConference; j <= i; j++) {

            let tmpExam = grades[j].exam['NoteoT'] ?? './.'
            let tmpVerbal = grades[j].verbal['NoteoT'] ?? './.'

            if (!isNaN(tmpExam)) {
                exam += parseInt(tmpExam)
                countExams++;
            }

            if (!isNaN(tmpVerbal)) {
                verbal += parseInt(tmpVerbal)
                countVerbals++;
            }
        }

        exam = exam / countExams
        verbal = verbal / countVerbals

        if (isNaN(exam)) {
            averages[i]['exam'] = './.';
        } else {
            averages[i]['exam'] = number.format(exam);
        }

        if (isNaN(verbal)) {
            averages[i]['verbal'] = './.';
        } else {
            averages[i]['verbal'] = number.format(verbal);
        }

        if (!isNaN(exam) && !isNaN(verbal)) {
            let mainPoints: string = "./."
            if (minorCourse) {
                // minor course is selected
                mainPoints = number.format(((exam + (verbal * 2)) / 3))
            } else {
                mainPoints = number.format((exam + verbal) / 2)
            }
            averages[i]['main'] = mainPoints;
        }

        if (isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = 0;
        }

        if (!isNaN(exam) && isNaN(verbal)) {
            averages[i]['main'] = number.format(exam);
        }

        if (isNaN(exam) && !isNaN(verbal)) {
            averages[i]['main'] = number.format(verbal);
        }
    }

    return averages;
}
