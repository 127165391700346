<template>
  <v-container fluid>
    <AbsentsTable :classes="classes"/>
    <br>
    <AbsentsStatistic :classes="classes"/>
  </v-container>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import AbsentsTable from "@/components/AbsentsTable.vue";
import AbsentsStatistic from "@/components/AbsentsStatistic.vue";

@Component({
  components: {AbsentsStatistic, AbsentsTable}
})
export default class Absents extends Vue {
  private classes: Array<any> = [];
  private http = useHttp();


  private async mounted() {
    this.http.get('classes').then((data: any) => {
      if (data) {
        this.classes.push(...data.data)
      }
    })
  }
}
</script>

<style scoped>

</style>
