import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {useToken} from '@/core/utils/token'

export const useHttp = () => {

    let token = useToken().getToken()

    const http = axios.create({
        baseURL: <string>import.meta.env.VITE_API_URL,
        headers: {
            'Accept': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        }
    })

    const get = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('get', url, payload, config)
    }

    const post = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('post', url, payload, config)
    }

    const put = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('put', url, payload, config)
    }

    const patch = <T>(url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {
        return request('patch', url, payload, config)
    }

    const destroy = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return request('delete', url, config)
    }

    const request = <T>(method: any, url: string, payload?: Record<string, any>, config?: AxiosRequestConfig): Promise<T> => {

        const requestConfig: AxiosRequestConfig = {
            url: url,
            method,
            [method === 'get' ? 'params' : 'data']: payload,
            ...config
        }

        return new Promise<T>((resolve, reject) => {
            http.request(requestConfig)
                .then((response: AxiosResponse) => {
                    resolve(response.data as T)
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status !== 422) {

                    }
                    reject(error.response)
                })
        })
    }

    return {
        get,
        post,
        put,
        patch,
        destroy,
        request
    }
}
