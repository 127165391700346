import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import moment from 'vue-moment'
import Notifications from 'vue-notification'
import * as Sentry from '@sentry/vue'
import {Integrations} from '@sentry/tracing'
import {useAuth} from '@/plugins/auth'
import App from '@/App.vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(moment)

navigator.serviceWorker.getRegistrations()
    .then(registrations => {
        registrations.forEach(registration => {
            registration.unregister()
        })
    })

if (import.meta.env.PROD) {
    Sentry.init({
        Vue,
        dsn: 'https://c6bc2de0fd2a42798169feab8a1cb8be@o616915.ingest.sentry.io/5750539',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        tracesSampleRate: 1.0,
    })
}

const guard = useAuth()

guard
    .auth()
    .finally(() => {
        const app = new Vue({
            store,
            router,
            vuetify,
            render: (h) => h(App),
        })

        const vm = app.$mount('#app')
    })

guard.watchToken()
