<template>
    <v-item-group class="flex row ml-6 mt-1">
        <v-autocomplete
            v-model="selectedClass"
            :items="classes"
            :return-object="true"
            class="mr-2"
            clearable
            item-text="full_name"
            item-value="IDFaecher"
            label="Klasse"
            no-data-text="Laden..."
            prepend-inner-icon="mdi-magnify"
            style="max-width: 160px"
            @change="selectClass"/>
        <v-autocomplete
            v-show="showStudentSelect"
            v-model="selectedStudent"
            :disabled="!students.length"
            :item-text="item => `${item.Nachname}, ${item.Vorname}`"
            :items="students"
            :return-object="true"
            class="mr-2"
            clearable
            item-value="IDSchueler"
            label="Schüler"
            no-data-text="Laden..."
            prepend-inner-icon="mdi-magnify"
            style="max-width: 320px"
            @change="selectStudent"/>
        <v-autocomplete
            v-model="selectedSubject"
            :disabled="!subjects.length"
            :item-text="item => `${item.FachName} (${item.fachkuerzel})`"
            :items="subjects"
            :return-object="true"
            class="mr-2"
            clearable
            item-value="IDFaecher"
            label="Fach"
            no-data-text="Laden..."
            prepend-inner-icon="mdi-magnify"
            style="max-width: 320px"
            @change="selectSubject"/>
    </v-item-group>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {useHttp} from "@/core/http";
import {useAuth} from "@/plugins/auth";
import {isEmpty} from "lodash";

@Component
export default class ClassStudentSubjectSelect extends Vue {
    @Prop({default: true}) showStudentSelect!: boolean
    @Prop() classData!: Array<any>
    @Prop({default: true}) onlyAssessableSubjects!: boolean

    private selectedClass: Record<string, any> = {}
    private selectedSubject: Record<string, any> = {}
    private selectedStudent: Record<string, any> = {}

    private classes: Array<any> = []
    private subjects: Array<any> = []
    private students: Array<any> = []

    private http = useHttp()
    private auth = useAuth()

    async selectClass() {
        this.$emit('updateClass', this.selectedClass)
        this.reset()
        if (!this.selectedClass) {
            return
        }

        this.subjects = this.selectedClass.subjects
        if (this.onlyAssessableSubjects) {
            this.subjects = this.selectedClass.subjects.filter((subject: {
                assessable: number;
            }) => subject.assessable == 1)
        }

        await this.loadStudents()
    }

    private async mounted() {
        if (!this.classData) {
            this.$emit('toggleLoading', true)
            this.http.get(`grades/classes/${this.auth.user().id}`).then((data: any) => {
                if (data) {
                    this.classes = data.data
                }

                this.$emit('toggleLoading', false)
            })
        } else {
            this.classes = this.classData
        }
    }

    private reset() {
        this.selectedStudent = {}
        this.$emit('updateStudent', this.selectedStudent)
        if (!this.currentClassHasCurrentSubject()) {
            this.selectedSubject = {}
            this.$emit('updateSubject', this.selectedSubject)
        }
    }

    private currentClassHasCurrentSubject() {
        return this.selectedClass?.subjects.some((subject: any) => subject.IDFaecher === this.selectedSubject?.IDFaecher)
    }

    private async selectStudent() {
        this.$emit('updateStudent', this.selectedStudent)
        if (isEmpty(this.selectedStudent)) {
            if (!isEmpty(this.selectedClass)) {
                this.subjects = this.selectedClass?.subjects
                if (this.onlyAssessableSubjects) {
                    this.subjects = this.selectedClass.subjects.filter((subject: {
                        assessable: number;
                    }) => subject.assessable == 1)
                }
            }
            return
        }

        await this.loadStudentSubjects()
    }

    private async selectSubject() {
        this.$emit('updateSubject', this.selectedSubject)
        if (isEmpty(this.selectedSubject)) {
            if (!isEmpty(this.selectedClass)) {
                this.loadStudents().then(() => this.$emit('toggleLoading', false))
            }
            return
        }

        await this.loadStudents();
    }

    private async loadStudents() {
        this.$emit('toggleLoading', true)

        if (this.selectedSubject?.is_elective === 1) {
            this.http.get(`subject/${this.selectedSubject.IDFaecher}/elective-students`)
                .then((data: any) => {
                    this.students = data.filter((student: any) => student.IDArtSchuleTatsechl == this.selectedClass.IDArtSchule)
                    this.$emit("updateStudents", this.students)
                    this.$emit('toggleLoading', false)
                })
        } else {
            this.http.get(`classes/${this.selectedClass.IDArtSchule}/students`)
                .then((data: any) => {
                    this.students = data
                    this.$emit("updateStudents", this.students)
                    this.$emit('toggleLoading', false)
                })
        }
    }

    private async loadStudentSubjects() {
        this.$emit('toggleLoading', true)

        this.http.get(`/student/${this.selectedStudent.IDSchueler}/electives`)
            .then((data: any) => {
                let electiveIDs: number[] = []
                data.forEach((elective: any) => electiveIDs.push(elective.IDFaecher))
                this.subjects = this.selectedClass.subjects.filter((subject: any) => {
                    return subject.assessable === 1
                        && (subject.is_elective === 1 && electiveIDs.includes(subject.IDFaecher)
                            || subject.is_elective !== 1)
                })
                this.$emit('toggleLoading', false)
            })
    }

}
</script>
