<template>
  <timetable-component></timetable-component>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator'
import TimetableComponent from '@/components/Timetable.vue'

@Component({
  components: {TimetableComponent}
})
export default class Timetable extends Vue {

}
</script>
